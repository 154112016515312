<template>
  <div v-if="activityInfo">
    <div class="sales-act" v-if="activityInfo.officialAvtivity && activityInfo.officialAvtivity.length">
      <h3>官方活动</h3>
      <div class="sales-banner" v-for="item in activityInfo.officialAvtivity">
        <img :src="item.show_img" />
        <!--分享按钮 -->
        <img class="share" :src="item.goto_params1" v-if="item.goto_params1" />
        <!--规则按钮 -->
        <img class="rules" :src="item.goto_params2" v-if="item.goto_params2" />
        <!--go按钮-->
        <a :href="item.click_goto" v-if="item.click_goto">
          <img class="go-to" src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230323142155573.png" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ActItem from './ActItem';
//linkType 1:跳user 2、跳本路径 3、跳h5
//type 1：促销活动 2：官方活动
export default {
  name: "ActivityConfig",
  props: {
    userIdentity: {
      type: Object,
      default() {
        return {}
      }
    },
    activityInfo: {
      type: Object,
      default() {
        return null
      }
    },
    userIdentityV4: {
      type: Number
    }
  },
  components: { ActItem },
  data() {
    return {

      couponList: []
    }
  },
  created() {

  },
  methods: {
    //打开规则页面
    openRuleLayer(item) {
      this.$emit('open', item);
    }

  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
.sales-act {
  padding-bottom: 10px;
  h3 {
    font-size: 16px;
    color: #666;
    padding: 10px 0;
  }

  .sales-banner {
    position: relative;

    img {
      width: 100%;
    }

    .go-to {
      position: absolute;
      width: 78px;
      top: 33px;
      right: 3%;
      -webkit-animation: scaleDraw 2s ease-in-out infinite;
    }
  }


}

@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}
</style>