<!-- 超V下单提醒 -->
<template>
  <div class="container">
    <div class="title">{{ type === 2 ? '温馨提示' : '调整说明' }}</div>
    <div class="illustrate" v-if="type === 2">
      <p v-if="userInfo.jkLevel === 2">60天内您没有有效的蔓薇订单,不能购买特惠专区商品哦。立即下单&gt;&gt;</p>
      <p v-else>您需定制超V商品大健康才可购买特惠专区商品噢！识别下方二维码前往小程序-蜜蛋店铺-超V定制</p>
      <div class="code-list">
        <div class="code-item">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220531103834724.png" />
          <div class="prompt">长按二维码识别进入</div>
        </div>
      </div>
    </div>
    <div class="illustrate" v-else>
      <p>亲爱的超V：</p>
      <p v-if="userInfo && userInfo.CardId > 0">因业务调整，请您前往小程序-蜜蛋店铺-超V界面</p>
      <template v-else>
        <p>第1步：请您识别[蜜蛋管理]二维码，登录激活您的店铺；</p>
        <p>第2步：激活店铺后识别[蜜蛋店铺]二维码，定制商品；</p>
        <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220610134737650.png" />
      </template>
      <div class="code-list">
        <div class="code-item" v-if="!userInfo || userInfo.CardId <= 0">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220610130242466.jpg" />
          <div class="prompt">蜜蛋管理</div>
          <div class="prompt">长按二维码识别进入</div>
        </div>
        <div class="code-item">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220531103834724.png" />
          <div class="prompt">蜜蛋店铺</div>
          <div class="prompt">长按二维码识别进入</div>
        </div>
      </div>
    </div>
    <div class="guide">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220531112020674.png" />
    </div>
    <!-- <img src="" /> -->
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    type: {
      type: Number,
      default: 1
    },
    userInfo: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang='scss' scoped>
.container {
  padding: 15px 15px 10px;
  box-sizing: border-box;
  text-align: center;
  background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220531111918249.png") no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .illustrate {
    width: 280px;
    font-size: 12px;
    padding: 11px 15px;
    border-radius: 6px;
    margin-bottom: 10px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);

    p {

      text-align: left;
      padding-bottom: 5px;
    }

    img {
      max-width: 80%;
    }

    .code-list {
      display: flex;

      .code-item {
        flex: 1;

        img {
          width: 80%;
          margin: 10px 0;
          box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
        }

        .prompt {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }



  }

  .guide {
    img {
      width: 100%;
    }
  }
}
</style>