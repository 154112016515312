<template>
    <div class="container">
        <div class="cus-header" v-if="agentInfo">
            <div class="title">{{type === 1 ?'当月新增连锁店铺':'已失效店铺'}}</div>
            <div class="chain-total">{{agentInfo.chainStoreTotal}}</div>
            <div class="brand-list">
                <div class="brand-item" :class="{'active' :brandId === 3}" @click="changeShopType(3)"
                     v-if="agentInfo.isAgentMz">
                    <span>美妆连锁店铺{{type === 1 ?'新增':'流失'}}</span>
                    <div>{{agentInfo.chainStoreMz}}</div>
                </div>
                <div class="brand-item" :class="{'active' :brandId === 4}" @click="changeShopType(4)"
                     v-if="agentInfo.isAgentDjk">
                    <span>大健康连锁店铺{{type === 1 ?'新增':'流失'}}</span>
                    <div>{{agentInfo.chainStoreDjk}}</div>
                </div>
            </div>
            <div class="select-time" @click="showTimeLayer = true">
                {{getTime}}
                <van-icon name="arrow-down"/>
            </div>
        </div>
        <div class="sub-header" v-if="agentInfo">{{type === 1 ?'当月新增连锁店铺':'已失效店铺'}}</div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getChainStoreInfo">
            <template v-if="agentInfo">
                <div class="empty" style="text-align: center" v-if="agentInfo.nllist  && agentInfo.nllist.length <= 0">
                    <img src="../../assets/images/empty/content.png"/>
                </div>
                <template v-else>
                    <div v-for="item in agentInfo.nllist" class="user-item">
                        <img class="media-object" :src="item.headImgUrl" v-if="item.headImgUrl"/>
                        <img class="media-object" src="../../assets/images/100.png" v-else/>
                        <div class="media-body">
                            <div class="name ellipsis1">{{item.agentName}}
                                <span v-if="item.describeTxt">({{item.describeTxt}})</span>
                            </div>
                            <div class="time">
                                <span class="icon-date"></span>
                                <span>{{item.times}}</span>
                                <!-- <span class="icon-tool"></span> -->
                                <!-- <span>{{item.agentCode}}</span> -->
                            </div>
                        </div>
                        <div class="view-info" @click="handleUser(item)">
                            <span>信息</span>
                            <span class="icon-arrow-right"></span>
                        </div>
                    </div>
                </template>
            </template>
        </van-list>
        <van-popup v-model="showTimeLayer" position="bottom">
            <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate"
                                 :max-date="maxDate" @confirm="onTimeConfirm" @cancel="showTimeLayer = false"/>
        </van-popup>
    </div>
</template>

<script>

  import {formatTimeArr} from "../../utils/formatTime";

  export default {
    name: "ShopChangeList",
    data() {
      return {
        brandId: 3,
        type: 1,  //1：当月新增连锁店铺  2 已失效店铺
        loading: false,
        finished: false,
        agentId: 0,
        agentInfo: null,
        currentDate: '',
        minDate: new Date(2017, 0, 1),
        maxDate: new Date(),
        pg: 0,
        ps: 10,
        time: "",
        showTimeLayer: false
      }
    },
    components: {},
    created() {
      let {params, query} = this.$route;
      if (query.brandId) this.brandId = +query.brandId;
      this.initParams(params);
    },
    methods: {
      initParams(params) {
        this.agentId = params.id;
        this.type = +params.type;
        this.time = params.time;
        this.currentDate = new Date(params.time)
      },
      //切换店铺类型
      changeShopType(brandId) {
        if (brandId === this.brandId) return;
        if (this.loading) return;
        this.brandId = brandId;
        this.finished = true;
        setTimeout(() => {
          this.agentInfo.nllist = null;
          this.loading = false;
          this.finished = false;
          this.pg = 0;
        }, 10);
      },
      //选择时间
      onTimeConfirm(value) {
        let time = formatTimeArr(value);
        this.time = time[0] + "-" + time[1];
        this.showTimeLayer = false;
        this.finished = true;
        setTimeout(() => {
          this.agentInfo.nllist = null;
          this.loading = false;
          this.finished = false;
          this.pg = 0;
        }, 10);
      },
      //获取连锁店详细列表
      getChainStoreInfo: function () {
        this.pg++;
        this.post("/TeamAgent/ChainStore/GetNewAndlossList", {
          agentId: this.agentId,
          dateTimes: this.time,
          pageIndex: this.pg,
          pageSize: this.ps,
          type: this.type,
          brandId: this.brandId
        }).then(json => {
          this.loading = false;
          let list = [];
          if (json.code === 1) {
            let data = json.response;
            list = data.nllist || [];
            if (this.pg === 1) {
              data.nllist = data.nllist || [];
              this.agentInfo = data;
            } else {
              let hisList = this.agentInfo.nllist
              this.agentInfo.nllist = hisList.concat(list);
            }
          }
          if (list.length < 10) {
            this.finished = true;
          }
        })
      },
      //查看用户信息
      handleUser(item) {
        this.$router.push({name: "UserInfo", params: {"id": item.agentId}});
      },

    },
    computed: {
      getTime() {
        let arr = this.time.split('-');
        if (arr.length > 0) {
          return arr[0] + "年" + arr[1] + "月"
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
    .container {
        text-align: center;
        background-color: #F8F8F8;
    }

    .cus-header {
        overflow: hidden;
        padding: 20px 0 10px;
        border-radius: 8px;
        background-color: #fff;
        position: relative;

        .title {
            font-size: 18px;
        }

        .chain-total {
            color: #FF98A0;
            font-size: 28px;
            padding-top: 10px;
        }

        .brand-list {
            display: flex;
            text-align: center;
            justify-content: center;

            .brand-item {
                flex: 1;
                color: #999;
                font-size: 12px;
                position: relative;
                padding: 11px 0 11px;

                div {
                    color: #666;
                    font-size: 16px;
                    padding-top: 10px;
                }

                &.active {
                    color: #FF98A0;

                    div {
                        color: #FF98A0;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -10px;
                        width: 60px;
                        height: 2px;
                        margin-left: -30px;
                        border-radius: 1px;
                        background-color: #FF98A0;
                    }
                }
            }
        }

        .select-time {
            position: absolute;
            top: 20px;
            right: 0;
            color: #666;
            padding: 7px 10px;
            border-radius: 30px 0 0 30px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            background-color: #fff;
        }
    }

    .sub-header {
        color: #666;
        margin: 10px 15px;
        text-align: left;
        font-size: 14px;
        line-height: 40px;
        padding-left: 15px;
        background-color: #fff;
    }

    .van-list {
        position: absolute;
        top: 213px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        text-align: left;
        padding: 0 15px;
        background-color: #F8F8F8;

        .empty {
            padding-bottom: 10px;

            img {
                width: 60%;
            }
        }

        .user-item {
            display: flex;
            padding: 11px 15px;
            align-items: center;
            background-color: #fff;

            .media-object {
                width: 33px;
                height: 33px;
                margin-right: 10px;
                border-radius: 50%;
            }

            .media-body {
                width: calc(100% - 85px);

                .name {
                    color: #666;
                    font-size: 14px;

                    span {
                        color: #999;
                        font-size: 12px;
                    }
                }

                .time {
                    padding-top: 8px;

                    span {
                        color: #AAA;
                        font-size: 11px;
                    }

                    .icon-date {
                        padding-right: 5px;
                    }

                    .icon-tool {
                        padding-left: 20px;
                        padding-right: 5px;
                    }
                }
            }

            .view-info {
                color: #999999;
                font-size: 11px;
                margin-left: 5px;

                span {
                    display: inline-block;
                    vertical-align: middle;
                }

                .icon-arrow-right {
                    font-size: 14px;
                }
            }
        }
    }
</style>