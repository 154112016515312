<template>
  <div id="DepositDetails">
    <div class="top_box">
      <div class="top_left_box">
        <div>总额：￥<span class="ivv-total">{{accountBalance.totalMoney}}</span>
          <van-popover v-model="showPopover" className="ivv-popover" trigger="click" :offset="[60,10]">
            <div style="font-size: 12px;background-color: rgb(235,235,235);color:#999;border-radius: 9px;padding: 1px 3px;">
              因保证金特殊性,此金额为预估,如有疑问请联系客服
            </div>
            <template #reference>
              <van-icon name="question" color="#999" size="18" />
            </template>
          </van-popover>
          <!--                    <van-button type="primary" color="#FF98A0" v-if="isAllow"
                                :to="'/refund_deposit?brandId=' + columns[type].brandId + '&type=1'">退保证金
                    </van-button>
                    <van-button type="primary"  :plain="true" color="#FF98A0" v-if="isApply"
                                :to="'/refund_deposit?brandId=' + columns[type].brandId">退保证金进度
                    </van-button>-->
        </div>
        <div>支出：￥{{accountBalance.expensesMoney}}<span style="display: inline-block;margin-right: 12px"></span>增加：￥{{accountBalance.incomeMoney}}
        </div>
      </div>
      <div @click="showModal = true" style="color: #ff98a0;display: flex;align-items: center">
        {{columns[type].name}}
        <van-icon style="margin-left: 4px" color="#ff98a0" name="arrow-down" />
      </div>
    </div>

    <van-list v-model="loading" :finished="finished" finished-text="已加载完全部数据" @load="onLoad">
      <div v-for="(item, index) in list" class="list_item">
        <img class="img" :src="item.bondMoney > 0 ? type1_img : type2_img" alt="">
        <div class="item_content">
          <div class="content_left">
            <div style="font-weight: bold;color: #6A6A6A">{{item.display}}</div>
            <div v-if="!!item.remark" style="margin-top: 8px;color: #6A6A6A">({{item.remark}})</div>
            <div style="margin-top: 8px;color: #999">{{item.createDate}}</div>
          </div>
          <div class="content_right" :style="item.bondMoney > 0 ? 'color: #ff98a0' : ''"><span v-if="item.bondMoney > 0">+</span>{{item.bondMoney}}
          </div>
        </div>
      </div>
    </van-list>

    <div class="overLay" v-if="showModal" style="z-index: 2;position:fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,.3)"></div>
    <van-picker title="" show-toolbar :columns="columns" @confirm="modalConfirm" @cancel="modalCancel" value-key="name" :default-index="type" style="position: fixed;z-index: 3;bottom: 0;left: 0;width: 100%;" v-if="showModal" />
  </div>
</template>

<script>
export default {
  name: "DepositDetails",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      type1_img: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210426145857095.png',
      type2_img: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210426150201573.png',
      accountBalance: {
        expensesMoney: 0,
        incomeMoney: 0,
        totalMoney: 0
      },
      columns: [{ name: '美妆', brandId: 3 }, { name: '大健康', brandId: 4 }],
      showModal: false,
      type: 0, //0美妆  1大健康
      page: 1,
      length: 20,
      isApply: false,//是否申请了退保证金
      isAllow: false, //是否允许退保证金
      showPopover: false,
    }
  },
  created() {
    document.title = '保证金明细'
  },
  methods: {
    onLoad() {
      this.loading = false
      if (this.loading) return
      this.loading = true
      this.post('/PersonCenter/TeamAgent/GetBondDetail', {
        brandId: this.columns[this.type].brandId,
        page: this.page,
        length: this.length
      }, true, 2).then(data => {
        this.loading = false
        if (data.code === 1) {
          let { detail, isapply, isAllow } = data.response;
          this.accountBalance.expensesMoney = detail.expensesMoney
          this.accountBalance.incomeMoney = detail.incomeMoney
          this.accountBalance.totalMoney = detail.totalMoney;
          this.isAllow = isAllow;
          this.isApply = isapply;
          this.list = this.list.concat(detail.detail)
          this.page++
          if (detail.detail.length < this.length) {
            this.finished = true
          }
        } else { 
          this.finished = true
        }
      })
    },
    modalConfirm(e, index) {
      if (index != this.type) {
        this.list = []
        this.page = 1
        this.finished = false
        this.type = index
        this.onLoad()
      }
      this.type = index
      this.showModal = false
    },
    modalCancel(e) {
      this.showModal = false
    }
  }
}
</script>

<style scoped lang="scss">
#DepositDetails {
  text-align: start;
  font-size: 12px;
  padding-top: 60px;
  font-family: 'Microsoft YaHei', Helvetica, sans-serif;

  .top_box {
    background-color: #f8f8f8;
    height: 60px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    .top_left_box {
      color: #999;
      font-size: 14px;

      .van-button {
        height: 16px;
        margin-left: 10px;
        border-radius: 16px;

        .van-button__content {
          font-size: 10px;
          font-weight: normal;
        }
      }

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .ivv-total {
        color: #666;
        font-size: 16px;
        font-weight: bold;
      }

      .van-icon-question {
        margin-left: 5px;
      }

      /deep/ .van-popover__wrapper {
        height: 18px;
      }
    }
  }

  .list_item {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 12px;
    display: flex;
    padding-bottom: 0;

    .img {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }

    .item_content {
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>