<template>
  <div id="app">
    <router-view />
    <div class="all-layer">
      <!-- 事关收益 二级商户 -->
      <a href="javascript:" @click="jumpRoute('/merchantIndex')" v-if="isShowMerchantLayer">
        <van-icon name="close" size="17" color="#FFB5BB" @click="isShowMerchantLayer = false" />
        <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230612140556842.png">
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      isShowMerchantLayer: false
    }
  }, created() {
    // this.checkIsOpenMerchant();
  }, methods: {
    //检查是否已开通二级商户
    checkIsOpenMerchant() {
      this.get("/Base/BaseOfficialAccount/SearchMerchantApply", {IgnoreLogin:true}, 3).then(res => {
        if (res.code === 1) {
          this.isShowMerchantLayer = res.response === 1;
        }
      })
    },
    jumpRoute(path) {
      const { fullPath } = this.$router.history.current;
      if (path && fullPath !== path) this.$router.push(path);
    }
  }
}
</script>

<style lang="scss" >
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  font-size: 12px;
  height: 100%;
}

.all-layer {
  position: fixed;
  left: 3%;
  bottom: 240px;

  a {
    width: 60px;
    position: relative;
    display: inline-block;

    img {
      width: 100%;
    }

    .van-icon {
      position: absolute;
      top: -6px;
      right: -6px;
    }
  }
}
</style>

