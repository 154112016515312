<template>
  <div class="sales-banner" @click="allIn(item)" :class="item.customClass">
    <img :src="item.bannerPath" />
    <!--分享按钮 -->
    <template v-if="item.showShare">
      <img class="share" :src="item.shareBtnPath" v-if="item.shareBtnPath" @click.stop="handleActivity(1,item)" />
      <span class="share" @click.stop="handleActivity(1,item)" v-else>分享</span>
    </template>
    <!--规则按钮 -->
    <template v-if="item.showRule">
      <img class="rules" :src="item.ruleBtnPath" v-if="item.ruleBtnPath" @click.stop="handleActivity(2,item)" />
      <span class="rules" @click.stop="handleActivity(2,item)" v-else>活动规则</span>
    </template>
    <!--go按钮-->
    <img class="go-to" :src="item.goBtnPath" v-if="item.goBtnPath" @click="handleActivity(3,item)" />
    <span class="go-to" @click="handleActivity(3,item)" v-else-if="item.linkType > 0 ">GO ></span>
    <!-- 特殊处理  gzhDJKcx20211212 -->
    <van-row v-if="item.customData" class="custom-content">
      <van-col span="10">
        <div class="slogan">
          <p>心动100小时</p>超值加赠
        </div>
        <div class="cut-down" v-if="item.customData.time > 0">
          <div class="label">距离结束</div>
          <van-count-down :time="item.customData.time">
            <template #default="timeData">
              <span class="block">{{ timeData.days * 24 + timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
        <van-button type="default" v-else>已结束</van-button>
      </van-col>
      <van-col span="8">
        <div class="slogan">
          <p>福利续航</p>爆款抢购
        </div>
        <div class="lucky-count">
          <div class="label">抽奖次数</div>
          <div class="num">{{ item.customData.luckNums }}</div>
        </div>
      </van-col>
      <van-col span="6">
        <div class="slogan">
          <p>幸运锦鲤</p>拼手气抽好礼
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return null
      }
    },
    userIdentity: {
      type: Object,
      default() {
        return {}
      }
    },
    userIdentityV4: {
      type: Number
    }
  },
  methods: {
    allIn(item) {
      if (item.anyWayGo) this.handleActivity(3, item)
    },
    //点击活动按钮
    handleActivity(type, item) {
      if (item.linkPath == '/PKIndex') {
        //争霸赛活动
        let { mzLevel } = this.userIdentity;
        if (mzLevel == 5) {
          //大区
          this.$router.push('/PKIndex')
        } else if ([3, 4].indexOf(mzLevel) >= 0) {
          //1级和省代
          this.$router.push('/FlagshipIndex/' + (mzLevel === 3 ? 2 : 1))
        } else {
          this.$dialog.confirm({
            title: '提示',
            message: '蜜蛋争霸赛为旗舰店主专享您暂时无法参与，赶紧开启旗舰店报名参与吧！',
            confirmButtonText: '开旗舰店'
          }).then(res => {
            window.location.href = this.baseHost + '/7999/Redesign/html/applyStore/pinkDiamond.html'
          })
        }
        return
      }
      switch (type) {
        case 1: //分享
          if (item.shareLinkType === 1 || item.shareLinkType === 3) {
            window.location.href = (item.shareLinkType === 1 ? this.userHost : (this.baseHost + '/7999')) + item.sharePath
          } else {
            this.$router.push(item.sharePath);
          }
          break;
        case 2://规则
          this.$emit('open', item);
          break;
        default: //go
          let { jkLevel, mzLevel } = this.userIdentity
          let level = item.brandTypeId === 4 ? jkLevel : mzLevel;
          if (level < item.minLevel && 1 === 2) { //此次不做此判断
            this.$dialog.confirm({
              title: '提示',
              message: '请先注册为旗舰店主!'
            }).then(() => {
              window.location.href = this.baseHost + '/7999/Redesign/html/applyStore/pinkDiamond.html'
            }).catch(() => {
            })
          } else if (level < 3 && !item.ignoreLevel) {
            if (item.moduleKey === 'gzhMZmzcz202103') return this.rechargeAct(item, level);
            if (this.userIdentityV4 == 2) { //取消授权直接跳转
              if (item.linkType === 1) {
                window.location.href = (item.linkType === 1 ? this.userHost : (this.baseHost + '/7999')) + item.linkPath
              } else if (item.linkType === 3) {
                window.location.href = this.baseHost + '/7999' + item.linkPath;
              } else {
                this.$router.push(item.linkPath);
              }
              return
            }
            //双品牌的活动特殊处理
            let specialDeal = item.moduleKey === 'gzhCGQmes202109' ? list[list.findIndex(it => {
              return it.Brandid === 4
            })].homelevel >= 2 : false
            if (level < 2 && !specialDeal) {
              let message = '您暂未开启专属店铺,如需采购请先开店噢!';
              this.$dialog.confirm({
                title: '提示',
                message: message,
                confirmButtonText: '确认'
              }).then(res => {
                window.location.href = this.baseHost + '/7999/Redesign/html/applyStore/greenDiamond.html?brandId=' + item.brandTypeId;
              }).catch(res => {
              });
            } else {
              if (item.linkType === 1) {
                window.location.href = (item.linkType === 1 ? this.userHost : (this.baseHost + '/7999')) + item.linkPath
              } else if (item.linkType === 3) {
                window.location.href = this.baseHost + '/7999' + item.linkPath;
              } else {
                this.$router.push(item.linkPath);
              }
            }
          } else if (level >= item.maxLevel) {
            this.$toast('12支梵洁诗商品采购订货为专卖店专享噢!');
          } else {
            if (item.linkType === 1 || item.linkType === 3) {
              window.location.href = (item.linkType === 1 ? this.userHost : (this.baseHost + '/7999')) + item.linkPath
            } else {
              this.$router.push(item.linkPath);
            }
          }
      }
    },
    //520充值活动特殊处理
    rechargeAct(item, level) {
      if (level < 2) {
        this.$dialog.confirm({
          title: '提示',
          message: '520为爱加冕活动为店主专享，如需参与请先开启店铺噢！',
          confirmButtonText: '确认开店'
        }).then(res => {
          window.location.href = this.userHost + '/rolorweb/html/recharge/register.html?brandId=' + item.bid;
        }).catch(res => {
        });
      } else {
        this.$router.push(item.linkPath);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.sales-banner {
  position: relative;
  /*overflow: hidden;*/
  margin-bottom: 10px;

  img {
    width: 100%;
  }
  .hint {
    position: absolute;
    right: 0;
    bottom: 2px;
    background-color: #fff;
    padding: 0 5px 0 7px;
    height: 22px;
    line-height: 22px;
    border-top-left-radius: 11px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.double111 {
    padding-bottom: 20px;
    .share,
    .rules {
      position: absolute;
      width: 71px;
      bottom: -1px;
    }
    .share {
      left: 16%;
    }
    .rules {
      right: 18%;
    }
    .go-to {
      position: absolute;
      width: 54px;
      top: 33px;
      right: 3%;
    }
  }

  &.double112 {
    .go-to {
      position: absolute;
      top: 11px;
      width: 55px;
      right: 44px;
    }

    .share {
      position: absolute;
      bottom: 3px;
      left: 53px;
      width: 74px;
      border-radius: 0;
    }

    .rules {
      position: absolute;
      left: 209px;
      bottom: 3px;
      width: 74px;
    }
  }
  &.double1Mw12 {
    padding-top: 20px;
    .go-to {
      position: absolute;
      top: 28px;
      width: 50px;
      right: 44px;
    }

    .share {
      position: absolute;
      top: -5px;
      left: 53px;
      width: 74px;
      border-radius: 0;
    }

    .rules {
      position: absolute;
      left: 209px;
      top: -5px;
      width: 74px;
    }
  }
  &.year_mw {
    .go-to {
      position: absolute;
      width: 25px;
      left: 66%;
      bottom: 16%;
      -webkit-animation: scaleDraw 1.5s ease-in-out infinite;
    }

    .share {
      position: absolute;
      left: 213px;
      bottom: -15px;
      width: 74px;
    }

    .rules {
      position: absolute;
      left: 45px;
      bottom: -15px;
      width: 74px;
    }
  }
  &.up-and-fee {
    .go-to {
      position: absolute;
      top: 88px;
      width: 75px;
      right: 118px;
    }

    .share {
      position: absolute;
      bottom: 3px;
      left: 53px;
      width: 74px;
      border-radius: 0;
    }

    .rules {
      position: absolute;
      left: 209px;
      bottom: 3px;
      width: 74px;
    }
  }
  &.pk-match {
    padding-top: 10px;
    .go-to {
      position: absolute;
      top: 50%;
      width: 45px;
      right: 20px;
      margin-top: -20px;
    }
  }
  .custom-content {
    position: absolute;
    left: 5px;
    right: 0;
    bottom: 21%;
    z-index: 100;
    text-align: center;
    .van-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slogan {
      color: #fff;
      font-size: 12px;
      transform: scale(0.8);
      -webkit-transform-origin-x: 0;
      -webkit-transform-origin-y: 30%;
      -webkit-transform: scale(0.8);
      p {
        font-size: 12px;
        font-weight: bold;
      }
    }
    .cut-down {
      margin-left: -8px;
      transform: scale(0.8);
      -webkit-transform-origin-x: 0;
      -webkit-transform-origin-y: 50%;
      -webkit-transform: scale(0.8);
      .label {
        color: #000;
        font-size: 12px;
      }
      .van-count-down {
        span {
          color: #000;
          font-size: 12px;
        }
        .block {
          width: 15px;
          line-height: 16px;
          display: inline-block;
          background-color: #cce718;
        }
      }
    }
    .van-button {
      height: 18px;
      padding: 0 5px;
      margin-left: 6px;
      border-color: #a4a4a4;
      background-color: #a4a4a4;
      .van-button__text {
        color: #000000;
        font-size: 9px;
      }
    }
    .lucky-count {
      margin-left: -5px;
      transform: scale(0.8);
      -webkit-transform-origin-x: 0;
      -webkit-transform-origin-y: 50%;
      -webkit-transform: scale(0.8);
      .label {
        font-size: 12px;
      }
      .num {
        width: 18px;
        height: 18px;
        margin-top: 5px;
        line-height: 18px;
        border-radius: 50%;
        color: #000000;
        margin: 5px auto 0;
        background-color: #cce718;
      }
    }
  }
}
</style>