import { render, staticRenderFns } from "./BrandProduct.vue?vue&type=template&id=49a26f04&scoped=true&"
import script from "./BrandProduct.vue?vue&type=script&lang=js&"
export * from "./BrandProduct.vue?vue&type=script&lang=js&"
import style0 from "./BrandProduct.vue?vue&type=style&index=0&id=49a26f04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a26f04",
  null
  
)

export default component.exports