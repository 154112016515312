import { render, staticRenderFns } from "./HomeRecommend.vue?vue&type=template&id=6590fdca&scoped=true&"
import script from "./HomeRecommend.vue?vue&type=script&lang=js&"
export * from "./HomeRecommend.vue?vue&type=script&lang=js&"
import style0 from "./HomeRecommend.vue?vue&type=style&index=0&id=6590fdca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6590fdca",
  null
  
)

export default component.exports