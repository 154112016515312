<template>
  <div class="subtitle" v-html="dataItem.title" :style="styleVar"></div>
</template>
<script>
export default {
  name: "ActivityTitle",
  props: {
    dataItem: {
      type: Object,
      value() {
        return {}
      }
    }
  },
  data() {
    return {
      styleVar: {
        "--color": this.dataItem.subColor
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitle {
  /deep/ span {
    position: relative;
    i {
      color: var(--color);
      font-style: normal;
      position: relative;
      z-index: 10;
      font-weight: bold;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      opacity: 0.6;
      border-radius: 4px;
      background-color: var(--color);
    }
  }
}
</style>
<style  scoped>
.subtitle span {
  color: #ff4444;
}
.subtitle span:after {
  background-color: var(color);
}
</style>