<template>
  <div class="rule-container" @click.stop :class="getRuleById.customClass" :style="getFullClass">
    <div class="rules-cont1" :class="{ 'full-screen': !isInPage }" :style="getRuleById.bgCss">
      <span class="modal-cha icon-delete-cha" @click="close" v-if="isInPage && !getRuleById.notShowClose"></span>
      <div class="rules-content">
        <van-tabs v-model="actRuleActive" @change="onChange" v-if="getRuleById.ruleList.length > 1">
          <van-tab :title="item.name" v-for="(item, index) in getRuleById.ruleList" :key="index">
            <rule-list :data-list="item.content" :is-in-page="isInPage" />
          </van-tab>
        </van-tabs>
        <rule-list :data-list="getRuleById.ruleList[0].content" :is-in-page="isInPage" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import RuleList from "./RuleList.vue"
export default {
  name: "RuleIndex",
  components: { RuleList },
  props: {
    isInPage: {
      type: Boolean,
      default: false
    },
    ruleIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      index: 0,
      actRuleActive: 0,
      actRuleModuleList: [
        {},
        {
          customClass: 'spring-mi',
          bgCss: 'background: #FFEF3C;',
          ruleList: [
            {
              name: '梵洁诗活动规则',
              content: [
                {
                  title: "<span>旗舰店活动规则</span>",
                  subColor: "#FFEF3C",
                },
                {
                  title: '<span>满额赠送</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173051979.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173137044.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173159311.png"
                  ]
                },
                {
                  title: '<span>新品首发</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173221523.png"
                  ]
                },
                {
                  title: '<span>满6送2</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825175227279.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825175251931.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825175304223.png"
                  ]
                },
                {
                  title: '<span>满6送1</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173258704.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173326174.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173400185.png"
                  ]
                },
                {
                  title: '<span>满5送1</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173440432.png"
                  ]
                },
                {
                  title: '<span>满4送1</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173507260.png"
                  ]
                },
                {
                  title: '<span>满3送1</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173531929.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173635303.png"
                  ]
                },
                {
                  title: '<span>金妍全家桶</span>',
                  subColor: '#FFEF3C',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220825173659589.png"
                  ]
                },
              ]
            }
          ]
        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '香蜜活动规则',
              content: [
                {
                  title: '',
                  subColor: '#E6AD66',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230413174240469.jpg"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'double11MW',
          bgCss: 'background: #9C98FF;',
          ruleList: [
            {
              name: '',
              content: [
                {
                  title: '<span><i>店主采购</i>订货规则</span>',
                  subColor: "#605AFF",
                  thirdColor: "#A6A2FF",
                  type: 3,
                  serialType: 1,
                  exchangeLink: '/Exchange',
                  btnColor: 'linear-gradient(to right, #FCCE73, #A693F5)',
                  list: [
                    {
                      title: '兑换券仅用于兑换指定商品，并随当前采购订单使用，2个或多个订单所获兑换券不关联不合计使用，且兑换券不支持找零；',
                      childList: []
                    },
                    {
                      title: '兑换区商品支持按零售价补充差额兑换；',
                      childList: []
                    },
                    {
                      title: '特惠套餐不参加满额送兑换券活动；明星单品套不参加满额送兑换券活动但会有对应匹配的兑换券；',
                      childList: []
                    },
                    {
                      title: '专卖店、旗舰店活动标准如下：',
                      childList: [
                        {
                          text: '专卖店采购订单：',
                          thirdColor: "#FECB1E",
                          serialType: 2,
                          list: [
                            { title: '专卖店采购订货金额满1500元，<span>送400元</span>产品兑换券；', childList: [] },
                            { title: '专卖店采购订单金额满3000元，<span>送1000元</span>产品兑换券；', childList: [] },
                            { title: '专卖店采购订单金额满4000元，<span>送1500元</span>产品兑换券；', childList: [] },
                          ]
                        },
                        {
                          text: '旗舰店采购订单：',
                          thirdColor: "#FECB1E",
                          serialType: 2,
                          list: [
                            { title: '旗舰店采购订货金额满3000元，<span>送1200元</span>产品兑换券；', childList: [] },
                            { title: '旗舰店采购订单金额满4000元，<span>送1800元</span>产品兑换券', childList: [] },
                            { title: '旗舰店采购订单金额满5000元，<span>送2400元</span>产品兑换券；', childList: [] },
                            { title: '旗舰店采购订单金额满6000元，<span>送3000元</span>产品兑换券；', childList: [] },
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  title: '<span><i>明星单品套</i>免费送</span>',
                  subColor: "#908BFF",
                  type: 1,
                  list: [
                    {
                      imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211028110710126.png',
                      txt: '',
                      worth: '',
                      title: '梵洁诗蓝钻皇后水肌源精华水(120ml)马卡龙炫彩水滢面膜（5片*3）(2选1）',
                      desc: '<div>购买【明星单品】梵洁诗鱼子酱菁萃焕亮礼盒时赠送</div>' +
                        '<span>叠加赠送兑换券</span><br/><span>专卖店：￥60&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;旗舰店：￥80</span>' +
                        '<div>购买以下商品时赠送：</div>' +
                        '<div>【明星单品】蜜乐儿净·晶透清逸面膜（5p*3) /组*3</div>' +
                        '<div>【明星单品】蜜乐儿润·水漾甜心面膜（5p*3)  /组*3</div>' +
                        '<div>【明星单品】蜜乐儿御·活妍修护面膜（5p*3)  /组*3</div>' +
                        '<div>【明星单品】蜜乐儿养·光采盈润面膜（5p*3)  /组*3</div>' +
                        '<span>叠加赠送兑换券</span><br/><span>专卖店：￥30&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;旗舰店：￥40</span>',
                      sublist: []
                    },
                    {
                      imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211028111304430.png',
                      txt: '',
                      worth: '',
                      title: '梵洁诗光采靓颜保湿水/梵洁诗凝时清润玉肌水（2选1）',
                      desc:
                        '<div>购买以下商品时赠送：</div>' +
                        '<div>【明星单品】梵洁诗滢润舒缓双萃精华乳</div>' +
                        '<div>【明星单品】梵洁诗塑颜紧致双萃精华乳</div>' +
                        '<div>【明星单品】梵洁诗光蕴焕亮双萃精华乳</div>' +
                        '<span>叠加赠送兑换券</span><br/><span>专卖店：￥45&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;旗舰店：￥60</span>',
                      sublist: []
                    },
                    {
                      imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211028111610651.png',
                      txt: '',
                      worth: '',
                      tagImage: '',
                      title: '梵洁诗鱼子酱眼部精华乳套组(正装+替换装）',
                      desc: '<div>购买【明星单品】梵洁诗鱼子酱菁萃膜力霜套组（正装+替换芯）时赠送</div>' +
                        '<span>叠加赠送兑换券</span><br/><span>专卖店：￥45&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;旗舰店：￥60</span>',
                      sublist: []
                    }
                  ]
                },
                {
                  title: '<span><i>特惠套餐</i></span>',
                  subColor: "#908BFF",
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/miShop/20211028111847000.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/miShop/20211028111857383.png"
                  ],
                  promptColor: '',
                  prompt: ''
                }
              ]
            }
          ],

        },
        {
          customClass: 'double11MW',
          bgCss: 'background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221017175647635.png") no-repeat;background-size:100% 100%;',
          ruleList: [
            {
              name: '',
              content: [
                {
                  title: '',
                  subColor: "#E6AD66",
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221017174958301.png"

                  ],
                },
              ]
            }
          ],

        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '旗舰店活动规则',
              content: [
                {
                  title: '',
                  subColor: "#E6AD66",
                  serialType: 0,
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031163541176.jpg"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '梵洁诗活动规则',
              content: [
                // {
                //   title: '<span><i>满额赠送</i>规则</span>',
                //   subColor: "#FF7BBC",
                //   serialType: 0,
                //   type: 3,
                //   list: [
                //     {
                //       serialType: 0,
                //       textColor: "#333333",
                //       title: '旗舰店活动标准如下：',
                //       secondColor: "#666666",
                //       childList: [
                //         {
                //           text: '旗舰店采购订单：',
                //           thirdColor: '#FF7BBC',
                //           serialType: 1,
                //           list: [
                //             {
                //               title: '旗舰店订单金额≥3000元，送<span>2个</span>新品尝鲜组合；'
                //             },
                //             {
                //               title: '旗舰店订单金额≥5000元，送<span>3个</span>新品尝鲜组合；'
                //             },
                //             {
                //               title: '旗舰店订单金额≥8000元，送<span>4个</span>新品尝鲜组合；'
                //             }
                //           ]
                //         }
                //       ]
                //     },
                //     {
                //       serialType: 0,
                //       textColor: "#333333",
                //       title: '旗舰店赠送实物标准如下：',
                //       secondColor: "#666666",
                //       childList: [

                //       ]
                //     }
                //   ]
                // },
                {
                  title: '',
                  subColor: '#E6AD66',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230413174410038.jpg"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'spring-mi',
          bgCss: 'background: #FF9AA7;',
          ruleList: [

            {
              name: '蔓薇活动规则',
              content: [
                {
                  title: '<span><i>限时七折开店</i></span>',
                  subColor: '#FF8B9A',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/miShop/20220406113957163.png"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'spring-mi',
          bgCss: 'background: #FCD3F6;',
          ruleList: [
            {
              name: '',
              content: [
                {
                  title: '<span><i>满额赠送</i>规则</span>',
                  subColor: "#FF94EF",
                  serialType: 0,
                  type: 3,
                  list: [
                    {
                      serialType: 0,
                      textColor: "#333333",
                      title: '旗舰店活动标准如下：',
                      secondColor: "#666666",
                      childList: [
                        {
                          text: '旗舰店采购订单：',
                          thirdColor: '#56BCFF',
                          serialType: 1,
                        }
                      ]
                    }
                  ]
                },
                {
                  title: '',
                  subColor: '#56BCFF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523175651144.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523175702742.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523175712523.png"
                  ]
                },
                {
                  title: '<span><i>旗舰店专享满3送1</i></span>',
                  subColor: '#FF94EF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113053463.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113109947.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113129727.png"
                  ]
                },
                {
                  title: '<span><i>旗舰店专享满6送3</i></span>',
                  subColor: '#FF94EF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180211442.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180235956.png"
                  ]
                },
                {
                  title: '<span><i>旗舰店专享满6送2</i></span>',
                  subColor: '#FF94EF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180257535.png",
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113159388.png"
                  ]
                },
                {
                  title: '<span><i>旗舰店专享满5送1</i></span>',
                  subColor: '#FF94EF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180334009.png"
                  ]
                },
                {
                  title: '<span><i>旗舰店专享混批满5送2</i></span>',
                  subColor: '#FF94EF',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180403691.png"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '蔓薇活动规则',
              content: [
                {
                  title: '',
                  subColor: '#E6AD66',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230313141155282.jpg",
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '梵洁诗活动规则',
              content: [
                {
                  title: '',
                  subColor: '#E6AD66',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160555719.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160605937.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160614276.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160622195.jpg"
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'september-beauty',
          bgCss: '',
          ruleList: [
            {
              name: '梵洁诗活动规则',
              content: [
                {
                  title: '',
                  subColor: '#E6AD66',
                  type: 2,
                  list: [
                    "http://freesaasofficedata.oss.aliyuncs.com/2222/d8660d1c765a4915bc78a2af6d01e0e27.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160605937.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160614276.jpg",
                    // "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230221160622195.jpg"
                  ]
                }
              ]
            }
          ]
        },
      ]
    }
  },
  created() {
    if (this.ruleIndex >= 0) {
      this.index = +this.ruleIndex;// 公众号首页传参过来
    } else {
      this.index = +this.$route.query.id; //app 链接传参过来
    }
    let { tabIndex } = this.$route.query;
    if (tabIndex) this.actRuleActive = +tabIndex;
  },
  methods: {
    onChange(index) {
      // this.actRuleActive = index
    },
    close() {
      this.$emit("close")
    },
    toExchange() {
      if (this.isInPage) {
        this.$router.push('/Exchange')
      } else {
        let message = { 'action': 'jumpExchangeZone' };
        if (this.index === 7) {
          message = { 'action': 'jumpExchangeZone' };
        }
        if (this.index == 2) {
          message = { 'action': 'jumpExchangeZone520' };
        }
        deliveryMessage(message)
      }
    }
  },
  computed: {
    getRuleById() {
      return this.actRuleModuleList[this.index]
    },
    getFullClass() {
      if (this.isInPage) return '';
      else return "position:absolute;top:0;left:0;right:0;bottom:0;height:100%;border-radius:0"
    }
  }
}
</script>
<style lang="scss" scoped>
.rule-container {
  height: 100%;
}

.rules-cont1 {
  position: relative;
  width: 100%;
  padding: 100px 10px 10px;
  box-sizing: border-box;
  background-size: 100%;
  height: 100%;

  .modal-cha {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    margin: 10px;
    z-index: 100;
    color: #eb3a62;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
  }

  .rules-content {
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    word-break: break-all;
    height: 100%;
    overflow-y: auto;

    .van-tabs {
      .van-tab {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          bottom: 25%;
          width: 1px;
          background-color: #f1f1f1;
        }

        &:first-child {
          &:before {
            width: 0;
          }
        }
      }

      .van-tab--active {
        color: #ff98a0;
      }
    }

    .operate-area {
      .van-button {
        border: none;
        background-color: #8830cc;
      }
    }
  }

  &.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    padding-top: 120px;
  }
}

.september-beauty {
  .rules-cont1 {
    padding: 0;

    .rules-content {
      padding: 0;
      border-radius: 0;
      background: transparent;

      .van-tabs {
        /deep/ .van-tabs__wrap {
          .van-tab {
            .van-tab__text {
              color: #666;
              padding: 2px 14px;
              border-radius: 24px;
              background: linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -o-linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -webkit-linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -moz-linear-gradient(to bottom, #f2f2f2, #bababa);
              box-shadow: 0 2px 0 #b5b5b5;
            }

            &.van-tab--active {
              .van-tab__text {
                color: #3a90ae;
                background: linear-gradient(to bottom, #beeeff, #4fc4ee);
                background: -o-linear-gradient(to bottom, #beeeff, #4fc4ee);
                background: -webkit-linear-gradient(
                  to bottom,
                  #beeeff,
                  #4fc4ee
                );
                background: -moz-linear-gradient(to bottom, #beeeff, #4fc4ee);
                box-shadow: 0 2px 0 #60c2e5;
              }
            }

            &:before {
              width: 0;
            }
          }

          .van-tabs__line {
            display: none;
          }
        }
      }

      .rules-detail {
        /deep/ .grapic-box {
          padding-top: 0;
          font-size: 0;
          line-height: 0;

          .txt-item {
            line-height: 0;
          }
        }
      }
    }
  }
}

.spring-mi {
  .rules-cont1 {
    padding-top: 10px;

    .rules-content {
      padding: 10px 20px;

      .van-tabs {
        /deep/ .van-tabs__wrap {
          .van-tab {
            .van-tab__text {
              color: #666;
              padding: 2px 14px;
              border-radius: 24px;
              background: linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -o-linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -webkit-linear-gradient(to bottom, #f2f2f2, #bababa);
              background: -moz-linear-gradient(to bottom, #f2f2f2, #bababa);
              box-shadow: 0 2px 0 #b5b5b5;
            }

            &.van-tab--active {
              .van-tab__text {
                color: #333;
                background: linear-gradient(to bottom, #fcd5fc, #f65bec);
                background: -o-linear-gradient(to bottom, #fcd5fc, #f65bec);
                background: -webkit-linear-gradient(
                  to bottom,
                  #fcd5fc,
                  #f65bec
                );
                background: -moz-linear-gradient(to bottom, #fcd5fc, #f65bec);
                box-shadow: 0 2px 0 #ce5cc7;
              }
            }

            &:before {
              width: 0;
            }
          }

          .van-tabs__line {
            display: none;
          }
        }
      }
    }
  }
}

.double11MW {
  .rules-cont1 {
    padding-top: 10px;

    .modal-cha {
      color: #bbd119;
    }

    .rules-content {
      /deep/ .rules-detail {
        .ivv-prompt {
          text-align: left;
        }
      }
    }
  }
}
</style>