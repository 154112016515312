<template>
    <div class="container">
        <van-search shape="round" v-model="value" show-action placeholder="请输入姓名/电话搜索" @search="getSearchList"
                    @cancel="onCancel"/>

        <!--  搜索历史  -->
        <div v-show="searchList.length <= 0 && hisList.length > 0" class="cont-box">
            <div class="search-history">
                <p>搜索历史</p>
                <i class="icon-delete" @click="removeHistory"></i>
            </div>
            <ul>
                <li v-for="(item,index)  in  hisList" :key="index" @click="onSearch(item)">{{item}}</li>
            </ul>
        </div>
        <div class="cus-list">
            <ManageUserList v-if="searchList.length > 0" :user-list="searchList" @change="viewOtherAgent"/>
        </div>
    </div>
</template>

<script>
  import ManageUserList from "../../components/ManageUserList";

  export default {
    name: "Search",
    data() {
      return {
        value: "",
        hisList: [],
        searchList: []
      }
    },
    components: {
      ManageUserList
    },
    created() {
      this.getHistory();
    },
    methods: {
      onSearch(val) {
        this.value = val;
        this.getSearchList();
      },
      onCancel() {
        this.$router.back();
      },
      //获取搜索列表
      getSearchList: function () {
        this.$toast.loading({message: '加载中...', duration: 0, overlay: true});
        this.post("/TeamAgent/ChainStore/GetSearchList", {
          search: this.value
        }).then((json) => {
          this.$toast.clear()
          if (json.code === 1) {
            this.searchList = json.response
          }
        })
      },
      //获取搜索历史
      getHistory: function () {
        this.get("/TeamAgent/ChainStore/GetSearchHistoryList").then((json) => {
          this.hisList = json.response.searchList
        });
      },
      removeHistory() {
        if (this.hisList.length <= 0) return;
        this.get("/TeamAgent/ChainStore/DeleteSearchHistory").then(json => {
          this.hisList = json.response
        });
      },
      //查看其它代理
      viewOtherAgent(item) {
        this.$router.push({name: "ShopManage", params: {"id": item.agentId}});
      },
    },
    watch: {
      value(newVal) {
        if (newVal === "") {
          this.searchList = [];
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .container {
        padding-top: 10px;
    }

    .cont-box {
        color: #999999;

        .search-history {
            display: flex;
            padding: 11px 15px;
            justify-content: space-between;

            > p {
                font-size: 14px;
            }

            .icon-delete {
                font-size: 18px;
            }
        }

        > ul {
            padding: 5px 15px;
            display: flex;
            flex-wrap: wrap;

            li {
                box-sizing: border-box;
                padding: 2px 10px;
                border-radius: 20px;
                background-color: #F1F1F1;
                font-size: 14px;
                margin: 0 10px 14px 0;
            }
        }
    }

    .cus-list {
        padding: 11px 15px;
    }
</style>