<template>
  <div @click.stop :class="getRuleById.customClass" :style="getFullClass">
    <div class="rules-cont" :class="{'full-screen': !isInPage }" :style="getRuleById.bgCss">
      <span class="modal-cha icon-delete-cha" @click="close" v-if="isInPage && !getRuleById.notShowClose"></span>
      <div class="rules-content" v-if="getRuleById.content">
        <van-tabs @click="onClick">
          <van-tab :title="it.title" v-for="(it,ii) in getRuleById.content" :key="ii"></van-tab>
        </van-tabs>
        <div class="rules-detail">
          <template v-for="(item,index) in getRuleById.content">
            <template v-for="(itt,jj) in item.list" keys="iit">
              <div class="subtitle" v-if="itt.subtitle" v-html="itt.subtitle" v-show="actRuleActive === index" :key="jj"></div>
              <template v-for="(it,bb) in itt.subList">
                <div class="list-detail" v-show="actRuleActive === index" :key="bb">
                  <div class="img-con">
                    <img :src="it.imageUrl">
                    <div class="the-price">
                      <div class="scale-font">{{it.txt}}</div>
                      <div class="second-line"><span class="scale-font" v-if="it.txt">￥</span>{{it.worth}}
                      </div>
                    </div>
                  </div>
                  <div class="mui-media-body">
                    <div class="gift-name" v-html="it.title"></div>
                    <p v-html="it.desc"></p>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <div class="subtitle_tips">*温馨提示*</div>
          <div class="subtitle_tips">1）蔓薇LAX系列(西梅)预计发货时间2020-10-25</div>
          <div class="subtitle_tips">2）蔓薇营养奶昔代餐粉(香草粉)、蔓薇营养奶昔代餐粉(巧克力味)【最佳品尝日期2021年2月】，采购订货后该产品需立即提货</div>
        </div>
      </div>
      <div class="rules-content" v-else>
        <div class="rules-detail exchange" v-if="getRuleById.content2">
          <div class="subtitle" v-html="getRuleById.content2.title"></div>
          <template v-for="(it,index) in getRuleById.content2.list">
            <div class="list-detail" :key="index">
              <span class="num">{{index+1}}</span>
              {{it.subtitle}}
              <div class="b-item" v-for="(iit,idx) in it.detailArr" :key="idx">
                <div class="name">{{iit.name}}</div>
                <div class="txt" v-for="(txt,ii) in iit.list" :key="ii">
                  <i>{{ii+1}})</i>
                  <div v-html="txt"></div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="operate-area" v-if="index === 4 || index === 7">
          <van-button type="primary" block round @click="toExchange">查看可兑换商品
            <van-icon name="arrow" />
          </van-button>
        </div>
        <div class="rules-detail" v-if="getRuleById.content3">
          <template v-for="item in getRuleById.content3">
            <div class="subtitle" v-html="item.title"></div>
            <div class="list-detail" v-for="it in item.list">
              <div class="img-con">
                <img :src="it.imageUrl">
                <!--                原油版本-->
                <!--                <div class="the-price" v-if="it.txt">-->
                <!--                  <div class="scale-font">{{it.txt}}</div>-->
                <!--                  <div class="second-line"><span class="scale-font">￥</span>{{it.worth}}-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="the-price" v-if="it.txt" :style="`background-image: url('${it.txt}')`">
                  <!--                  <div class="scale-font">{{it.txt}}</div>-->
                  <!--                  <div class="second-line"><span class="scale-font">￥</span>{{it.worth}}-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="mui-media-body">
                <div class="gift-name" v-html="it.title"></div>
                <div class="gift-item" v-for="(itt,idx) in it.sublist">
                  <span>{{idx + 1}}</span> {{itt}}
                </div>
                <p v-html="it.desc"></p>
              </div>
            </div>
            <div class="ivv-prompt" v-if="item.prompt" v-html="item.prompt" :style="'color:' + item.promptColor"></div>
          </template>
          <template v-if="index === 1">
            <!--                        <div class="subtitle_tips font-gray">*提示：优先赠送身体霜,当身体霜库存不足时自动更换赠送蜜粉饼</div>-->
            <div class="subtitle_tips">
              <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210824141139238.png" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import product01 from '../../assets/images/home/activity/product_01.png';
import product02 from '../../assets/images/home/activity/product_02.png';
import product03 from '../../assets/images/home/activity/product_03.png';
import { deliveryMessage } from '../../utils/appUtil'

export default {
  name: "Rule",
  props: {
    isInPage: false,
    ruleIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      index: 0,
      actRuleActive: 0,
      actRuleModuleList: [
        {
          bgCss: 'background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210208164510018.png") no-repeat #191919; background-size: 100%;',
          content: [
            {
              title: '满6送1',
              list: [
                {
                  subtitle: '',
                  subList: [
                    {
                      imageUrl: product03,
                      txt: '',
                      worth: '自选',
                      title: '梵洁诗璀璨睛彩焕亮精华乳(278/瓶)、梵洁诗璀璨睛彩密集精华液(338/瓶)<span>两者自选1瓶</span>',
                      desc: '梵洁诗光蕴焕亮双萃精华乳、梵洁诗塑颜紧致双萃精华乳、梵洁诗滢润舒缓双萃精华乳、梵洁诗悦活控油双萃精华乳、梵洁诗光采靓颜保湿水、梵洁诗绿钻公主凝时清润玉肌水、梵洁诗鱼子酱精华水肌源乳液膜力霜、梵洁诗鱼子酱水光珍奢精灵礼盒，任意组合<span>混批满6件</span>时赠送'
                    }
                  ]
                }
              ]
            },
            {
              title: '满额叠加赠送',
              list: [
                {
                  subtitle: '',
                  subList: [
                    {
                      imageUrl: product01, txt: '价值', worth: 238, title: '梵洁诗倾慕之光香水',
                      desc: '<span>专卖店店主</span>：订单金额≥<span>1500元</span>，额外叠加赠送'
                    },
                    {
                      imageUrl: product02,
                      txt: '',
                      worth: '2套',
                      title: '香珂奢宠鎏金身体套(￥258/套)或梵洁诗持久丝绒唇釉礼盒(￥278/盒)自由组合2套',
                      desc: '<span>旗舰店店主</span>：订单金额≥<span>4000元</span>，额外叠加赠送'
                    },
                    {
                      imageUrl: product02,
                      txt: '',
                      worth: '3套',
                      title: '香珂奢宠鎏金身体套(￥258/套)或梵洁诗持久丝绒唇釉礼盒(￥278/盒)自由组合3套',
                      desc: '<span>旗舰店店主</span>：订单金额≥<span>5000元</span>，额外叠加赠送'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          customClass: 'new-year-mw',
          bgCss: 'background: #cdf2ff;',
          content3: [
            {
              title: '<span><i>满6送1</i></span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210910174431011.png',
                  txt: '',
                  worth: '',
                  title: '山茶花润泽双效面膜（首发限量版）',
                  desc: '山茶花润泽舒缓喷雾、山茶花润泽精华水、山茶花润泽精华乳霜、山茶花润泽双效面膜<br/>' +
                    '<span>以上四件任意组合混批：满6送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210824135423941.png',
                  txt: '',
                  worth: '',
                  title: '丝柔双萃呼吸霜/幻彩丝柔双萃粉底霜<span> 2选1</span>',
                  desc: '幻彩丝柔双萃呼吸霜、幻彩丝柔双萃粉底霜、幻彩丝柔双萃防护乳、幻彩晶蓝凝萃卸妆乳、金妍滢润光采洁颜凝露<br/>' +
                    '<span>以上五件任意组合混批：满6送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210824135650691.png',
                  txt: '',
                  worth: '',
                  title: '蓝钻皇后水肌源精华水/梵洁诗鱼子酱精华水肌源乳液膜力霜<span> 2选1</span>',
                  desc: '鱼子酱精华水肌源乳液膜力霜、鱼子酱菁萃焕亮礼盒、蓝钻皇后水肌源精华水金妍滢亮恒润柔肤水、鱼子酱水光珍奢精灵礼盒<br/>' +
                    '<span>以上五件任意组合混批：满6送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210824135859347.png',
                  txt: '',
                  worth: '',
                  title: '塑颜紧致双萃精华乳/滢润舒缓双萃精华乳/光蕴焕亮双萃精华乳/悦活控油双萃精华乳<span> 4选1</span>',
                  desc: '塑颜紧致双萃精华乳、滢润舒缓双萃精华乳、光蕴焕亮双萃精华乳、悦活控油双萃精华乳' +
                    '<span>以上四件任意组合混批：满6送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210824140143878.png',
                  txt: '',
                  worth: '',
                  title: '清润玉肌水/光采靓颜保湿水<span> 2款任选其1</span>',
                  desc: '粉钻甜心靓颜礼盒(迷你版)、绿钻公主活颜礼盒 、光采靓颜保湿水、凝时清润玉肌水<br/>' +
                    '<span>以上四件任意组合混批：满6送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210910175641531.png',
                  txt: '',
                  worth: '',
                  title: '御·活妍修护面膜（拍1发3）<br/>' +
                    '养·光采盈润面膜（拍1发3）<br/>' +
                    '润·水漾甜心面膜（拍1发3）<br/>' +
                    '净·晶透清逸面膜（拍1发3）' + '<span> 4选1</span>',
                  desc: '御·活妍修护面膜(拍1发3)、养·光采盈润面膜(拍1发3)、润·水漾甜心面膜(拍1发3)、净·晶透清逸面膜(拍1发3)、马卡龙炫彩水滢面膜(拍1发2)<br/>' +
                    '<span>以上五件任意组合混批：满6送1</span>',
                  sublist: []
                }
              ]
            },
            {
              title: '<span><i>满3送1</i></span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210910175954608.png',
                  txt: '',
                  worth: '',
                  title: '梵洁诗舒宝倍护润肤乳',
                  desc: '梵洁诗舒宝倍护润肤乳<br/>' +
                    '<span>以上商品：满3送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210910180006624.png',
                  txt: '',
                  worth: '',
                  title: '珍奢倍润修护元气霜',
                  desc: '珍奢倍润修护元气霜<br/>' +
                    '<span>以上商品：满3送1</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210910180018404.png',
                  txt: '',
                  worth: '',
                  title: '金妍幻彩气垫粉底霜（含替芯）',
                  desc: '金妍幻彩气垫粉底霜（含替芯）<br/>' +
                    '<span>以上商品：满3送1</span>',
                  sublist: []
                },
              ]
            },
          ]
        },
        {
          customClass: 'recharge_fjs',
          bgCss: 'background-image: url(http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309175414739.png); background-size: 100%;',
          content2: {
            title: '<span>520为爱加冕<i>活动规则</i></span>',
            list: [
              {
                subtitle: "购520元预存订货券尊享助力升值：666元美妆兑换券和128元梵洁诗瓷肌柔光定妆蜜粉饼1件；",
                detailArr: []
              },
              {
                subtitle: "520元预存订货券专卖店仅支持购买1张，旗舰店无限制；",
                detailArr: []
              },
              {
                subtitle: '购买的520元为预存订货券仅用于美妆商品采购订货时使用，1个采购订单仅支持使用1张520元预存订货券；',
                detailArr: []
              },
              {
                subtitle: '666元美妆兑换券需随采购订单且订单金额≥1800元在美妆兑换专区兑换商品时使用，1个采购订单仅支持使用1张666元美妆兑换券且不支持找零，梵洁诗瓷肌柔光定妆蜜粉饼将随该兑换券使用一起发货；',
                detailArr: []
              },
            ]
          },
        },
        {
          customClass: 'actDouble11',
          bgCss: 'background-image: url(http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019163620060.png);',
          content3: [
            {
              title: '<span><i>满3送1</i>产品</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019150135759.png',
                  txt: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019141528767.png',
                  worth: '',
                  title: '蜂胶宝 或 儿童乳钙软胶囊 (牛奶味）（2选1）',
                  desc: '<div>蔓薇OMEGA-3深海角鲨烯鱼油</div>' +
                    '<div class="desc_color1">以上商品：满3送1</div>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019150135759.png',
                  txt: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019141528767.png',
                  worth: '',
                  title: '蜂胶宝 或 儿童乳钙软胶囊 (牛奶味）（2选1）',
                  desc: '<div>蔓薇D3复合钙片</div>' +
                    '<div class="desc_color1">以上商品：满3送1</div>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019150135759.png',
                  txt: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019141528767.png',
                  worth: '',
                  title: '蜂胶宝 或 儿童乳钙软胶囊 (牛奶味）（2选1）',
                  desc: '<div>蔓薇活力酶BW10000U美白片</div>' +
                    '<div class="desc_color1">以上商品：满3送1</div>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019150741266.png',
                  txt: '',
                  worth: '',
                  title: '蔓薇奶昔（4种口味选1）',
                  desc: '<div>蔓薇NAD+抗衰老逆龄胶囊</div>' +
                    '<div class="desc_color1">以上商品：满3送1</div>',
                  sublist: []
                }
              ]
            },
            {
              title: '<span><i>满6送1</i></span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019164623490.png',
                  txt: '',
                  worth: '',
                  title: '蔓薇LAX系列（西梅）',
                  desc: '蔓薇LAX系列（西梅）、蔓薇CLEANSE系列(蓝莓)、蔓薇CLEANSE系列（蔓越莓）、营养代餐奶昔（香草味)、营养代餐奶昔（巧克力味)、营养代餐奶昔（香蕉味)、营养代餐奶昔（草莓味）<span>以上七件任意组合混批：满6送1</span>',
                  sublist: []
                }
              ]
            },
          ],
          notShowClose: true
        },
        {
          customClass: 'double12',
          bgCss: 'background-image: url(http://freesaasofficedata.oss.aliyuncs.com/miShop/20201125154111586.png); background-size: 100%;',
          content2: {
            title: '<span><i>店主采购</i>订货</span><br/><span>规则</span>',
            list: [
              {
                subtitle: "兑换券仅用于兑换指定商品，并随当前采购订单使用，2个或多个订单所获兑换券不关联不合计使用，且兑换券不支持找零；",
                detailArr: []
              },
              {
                subtitle: "兑换区商品支持按零售价补充差额兑换；",
                detailArr: []
              },
              {
                subtitle: '专卖店、旗舰店活动标准如下：',
                detailArr: [
                  {
                    name: '专卖店采购订单:',
                    list: ['专卖店采购订货金额满1500元，<span>送300元</span>正装产品兑换券；', '专卖店采购订单金额满3000元，<span>送800元</span>正装产品兑换券；', '专卖店采购订单金额满4000元，<span>送1300元</span>正装产品兑换券；']
                  },
                  {
                    name: '旗舰店采购订单：',
                    list: ['旗舰店采购订货金额满3000元，<span>送1000元</span>正装产品兑换券；', '旗舰店采购订单金额满4000元，<span>送1500元</span>正装产品兑换券；', '旗舰店采购订单金额满5000元，<span>送2000元</span>正装产品兑换券；', '旗舰店采购订单金额满6000元，<span>送2500元</span>正装产品兑换券；']
                  }
                ]
              },
            ]
          }
        },
        {
          customClass: 'new-year',
          bgCss: 'background-image: url(http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225175025644.png); background-size: 100%;',
          content3: [
            {
              title: '<span><i>免费送</i>产品</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225175915363.png',
                  txt: '官方价',
                  worth: '198',
                  title: '御·活妍修护面膜/养·光采盈润面膜/润·水漾甜心面膜/净·晶透清逸面膜4选1',
                  desc: '以上8件商品任意组合<span>混批满6件</span>时赠送',
                  sublist: [
                    '梵洁诗塑颜紧致双萃精华乳（另含肌因活性氨基酸洁面乳20ml*2+梵洁诗肌因活性水肌源20ml*2）；',
                    '梵洁诗滢润舒缓双萃精华乳（另含凝时清润玉肌洁面乳20ml*2+梵洁诗凝时清润玉肌水20ml*2）；',
                    '梵洁诗光蕴焕亮双萃精华乳（另含肌因活性氨基酸洁面乳20ml*2+梵洁诗肌因活性水肌源20ml*2）；',
                    '梵洁诗悦活控油双萃精华乳（另含凝时清润玉肌洁面乳20ml*2+梵洁诗凝时清润玉肌水20ml*2）；',
                    '梵洁诗幻彩丝柔双萃呼吸霜；',
                    '梵洁诗鱼子酱菁萃焕亮礼盒；',
                    '梵洁诗鱼子酱水光珍奢精灵礼盒;',
                    '梵洁诗鱼子酱精华水肌源乳液膜力霜；'
                  ]
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225180604206.png',
                  txt: '官方价',
                  worth: '220',
                  title: '马卡龙炫彩水滢面膜1盒',
                  desc: '以上7件商品任意组合<span>混批满6件</span>时赠送',
                  sublist: [
                    '梵洁诗金妍亮采光嫩日霜；',
                    '梵洁诗金妍修护蕴能晚霜；',
                    '梵洁诗金妍水漾多氧精华；',
                    '梵洁诗金妍幻彩气垫粉底霜；',
                    '梵洁诗金妍摇醒青春精华液；',
                    '金妍滢亮恒润柔肤水；',
                    '梵洁诗美白多重阳光防晒乳 SPF50+ PA++++；',
                  ]
                }
              ]
            },
            {
              title: '<span><i>专卖店</i>满额叠加赠送</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225180856483.png',
                  txt: '官方价',
                  worth: '594',
                  title: '梵洁诗倾色滢润口红礼盒1盒',
                  desc: '订单金额<span>≥3000元</span>时额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225181000904.png',
                  txt: '官方价',
                  worth: '1188',
                  title: '梵洁诗倾色滢润口红礼盒2盒',
                  desc: '订单金额<span>≥4500元</span>时额外叠加赠送',
                  sublist: []
                },

              ]
            },
            {
              title: '<span><i>旗舰店</i>满额叠加赠送</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225181000904.png',
                  txt: '官方价',
                  worth: '1188',
                  title: '梵洁诗倾色滢润口红礼盒2盒',
                  desc: '订单金额<span>≥3000元</span>时额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225181524118.png',
                  txt: '官方价',
                  worth: '1782',
                  title: '梵洁诗倾色滢润口红礼盒3盒',
                  desc: '订单金额<span>≥4500元</span>时额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225181535180.png',
                  txt: '官方价',
                  worth: '2376',
                  title: '梵洁诗倾色滢润口红礼盒4盒',
                  desc: '订单金额<span>≥6000元</span>时额外叠加赠送',
                  sublist: []
                },
              ]
            },
            {
              title: '<span><i>双11活动下单店主</i>额外送</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225175915363.png',
                  txt: '官方价',
                  worth: '198',
                  title: '御·活妍修护面膜/养·光采盈润面膜/润·水漾甜心面膜/净·晶透清逸面膜4选1',
                  desc: '订单金额<span>≥3000元</span>时额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201225180604206.png',
                  txt: '官方价',
                  worth: '220',
                  title: '马卡龙炫彩水滢面膜1盒',
                  desc: '订单金额<span>≥4500元</span>时额外叠加赠送',
                  sublist: []
                },

              ]
            },
          ]
        },
        {
          customClass: 'new-year',
          bgCss: 'background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317104252154.png"); background-size: 100%;',
          content3: [
            {
              title: '<span><img style="margin-right: 5px;transform: scale(0.8)" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174134274.png" alt=""><i>满6送1</i>规则<img style="margin-left: 5px;transform: scale(0.8)" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174417062.png" alt=""></span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317154718199.png',
                  txt: '',
                  worth: '',
                  title: '幻彩丝柔双萃粉底霜',
                  desc: '幻彩丝柔双萃呼吸霜、幻彩丝柔双萃粉底霜、幻彩丝柔双萃防护乳，以上3件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317155459014.png',
                  txt: '',
                  worth: '',
                  title: '金妍幻彩气垫粉底霜',
                  desc: '金妍滢润光采洁颜凝露、金妍多重美白阳光防晒乳、金妍幻彩气垫粉底霜，以上3件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317160028933.png',
                  txt: '',
                  worth: '',
                  title: '凝时清润玉肌水',
                  desc: '粉钻甜心靓颜礼盒(迷你版)、光采靓颜保湿水、凝时清润玉肌水，以上3件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210322143014790.png',
                  txt: '',
                  worth: '',
                  title: '绿钻公主活颜礼盒',
                  desc: '粉钻甜心靓颜礼盒（2020版）、蓝钻皇后逆颜礼盒（2020版）、绿钻公主活颜礼盒 (2020版）,以上3件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210325144204617.png',
                  txt: '',
                  worth: '',
                  title: '金妍摇醒青春精华液',
                  desc: '金妍亮采光嫩日霜、金妍修护蕴能晚霜、金妍水漾多氧精华乳、金妍滢亮恒润柔肤水、金妍摇醒青春精华液,以上3件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210322143512165.png',
                  txt: '',
                  worth: '',
                  title: '悦活控油双萃精华乳',
                  desc: '光蕴焕亮双萃精华乳、塑颜紧致双萃精华乳、滢润舒缓双萃精华乳、悦活控油双萃精华乳，以上4件商品混批<span>满6件送1件</span>',
                  sublist: []
                },
              ]
            },
            {
              title: '<span><img style="margin-right: 5px;transform: scale(0.8)" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174134274.png" alt=""><i>旗舰店满金额</i>额外送<img style="margin-left: 5px;transform: scale(0.8)" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174417062.png" alt=""></span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174355758.png',
                  txt: '',
                  worth: '',
                  title: '金妍滢润光采洁颜凝露',
                  desc: '订单金额满<span class="blue_words">3000元</span>额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174410380.png',
                  txt: '',
                  worth: '',
                  title: '金妍滢润光采洁颜凝露',
                  desc: '订单金额满<span class="blue_words">4500元</span>额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317174420485.png',
                  txt: '',
                  worth: '',
                  title: '金妍滢润光采洁颜凝露',
                  desc: '订单金额满<span class="blue_words">6000元</span>额外叠加赠送',
                  sublist: []
                },
              ]
            },
          ]
        },
        {
          customClass: 'double11',
          bgCss: 'background-image: url(http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423182053059.png); background-size: 100%;',
          content2: {
            title: '<span><i>店主采购</i>订货规则</span>',
            list: [
              {
                subtitle: "兑换券仅用于兑换指定商品，并随当前采购订单使用，2个或多个订单所获兑换券不关联不合计使用，且兑换券不支持找零；",
                detailArr: []
              },
              {
                subtitle: "兑换区商品支持按零售价补充差额兑换；",
                detailArr: []
              },
              {
                subtitle: '专卖店、旗舰店活动标准如下：',
                detailArr: [
                  {
                    name: '专卖店采购订单:',
                    list: ['专卖店采购订单金额满1500元，<span>送200元</span>正装产品兑换券；', '专卖店采购订单金额满3000元，<span>送500元</span>正装产品兑换券；', '专卖店采购订单金额满4500元，<span>送800元</span>正装产品兑换券；']
                  },
                  {
                    name: '旗舰店采购订单：',
                    list: ['旗舰店采购订单金额满3000元，<span>送600元</span>正装产品兑换券；', '旗舰店采购订单金额满4500元，<span>送1000元</span>正装产品兑换券；', '旗舰店采购订单金额满6000元，<span>送1800元</span>正装产品兑换券；']
                  }
                ]
              },
            ]
          },
          content3: [
            {
              title: '<span><i>专卖店满额叠加送</i>产品</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423173412450.png',
                  txt: '',
                  worth: '',
                  title: '蜜乐儿养•光采盈润面膜1盒',
                  desc: '当订单金额<span>满1500元</span>，额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423170241540.png',
                  txt: '',
                  worth: '',
                  title: '马卡龙炫彩水滢面膜1盒',
                  desc: '当订单金额<span>满3000元</span>，额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423170241540.png',
                  txt: '',
                  worth: '',
                  title: '马卡龙炫彩水滢面膜2盒',
                  desc: '当订单金额<span>满4500元</span>，额外叠加赠送',
                  sublist: []
                },
              ]
            },
            {
              title: '<span><i>旗舰店满额叠加送</i>产品</span>',
              list: [
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423170241540.png',
                  txt: '',
                  worth: '',
                  title: '马卡龙炫彩水滢面膜2盒',
                  desc: '当订单金额<span>满3000元</span>，额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423170241540.png',
                  txt: '',
                  worth: '',
                  title: '马卡龙炫彩水滢面膜3盒',
                  desc: '当订单金额<span>满4500元</span>，额外叠加赠送',
                  sublist: []
                },
                {
                  imageUrl: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210423170241540.png',
                  txt: '',
                  worth: '',
                  title: '马卡龙炫彩水滢面膜4盒',
                  desc: '当订单金额<span>满6000元</span>，额外叠加赠送',
                  sublist: []
                },
              ]
            },
          ]
        },
      ]
    }
  },
  created() {
    if (this.ruleIndex >= 0) {
      this.index = this.ruleIndex;// 公众号首页传参过来
    } else {
      this.index = +this.$route.query.id; //app 链接传参过来
    }
    console.log(this.actRuleModuleList[this.index])
  },
  methods: {
    onClick(index) {
      this.actRuleActive = index
    },
    close() {
      this.$emit("close")
    },
    toExchange() {
      if (this.isInPage) {
        // window.location.href = this.baseHost + "/7999/Arongleweb/html/fjxActivity/exchange.html"
        this.$router.push('/Exchange')
      } else {
        let message = { 'action': 'jumpExchangeZone' };
        if (this.index === 7) {
          message = { 'action': 'jumpExchangeZone' };
        }
        if (this.index == 2) {
          message = { 'action': 'jumpExchangeZone520' };
        }
        deliveryMessage(message)
      }
    }
  },
  computed: {
    getRuleById() {
      return this.actRuleModuleList[this.index]
    },
    getFullClass() {
      if (this.isInPage) return '';
      else return "position:absolute;top:0;left:0;right:0;bottom:0;height:100%;border-radius:0"
    }
  }
}
</script>

<style lang="scss">
.rules-cont {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px;
  box-sizing: border-box;
  background: url("../../assets/images/home/activity/banner_fjs_01.png")
    no-repeat #e5f2f8;
  background-size: 100%;
  height: calc(100% - 100px);

  .modal-cha {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    margin: 10px;
    color: #eb3a62;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
  }

  .rules-content {
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    word-break: break-all;
    height: 100%;
    overflow-y: auto;

    .van-tabs {
      .van-tab {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 25%;
          left: 0;
          bottom: 25%;
          width: 1px;
          background-color: #f1f1f1;
        }

        &:first-child {
          &:before {
            width: 0;
          }
        }
      }

      .van-tab--active {
        color: #ff98a0;
      }
    }

    .rules-detail {
      position: relative;
      overflow-y: auto;

      .subtitle_tips {
        font-size: 12px;
        color: rgb(254, 153, 161);
        text-align: left;

        &.font-gray {
          color: #999;
          text-align: center;
        }

        img {
          width: 100%;
        }
      }

      .subtitle {
        font-size: 16px;
        padding-top: 10px;
        letter-spacing: 0.5px;

        i {
          font-style: normal;
        }

        span {
          color: #ff98a0;
          position: relative;

          /*&:after {*/
          /*    content: '';*/
          /*    position: absolute;*/
          /*    left: 0;*/
          /*    right: 0;*/
          /*    bottom: 0;*/
          /*    height: 4px;*/
          /*    border-radius: 4px;*/
          /*    background-color: rgba(225, 152, 160, 0.2);*/
          /*}*/
        }
      }

      .list-detail {
        padding: 20px 0 10px;
        border-bottom: solid 1px #f1f1f1;
        display: flex;

        .mui-media-body {
          text-align: left;
          overflow: hidden;
          width: calc(100% - 85px);

          .gift-name {
            color: #333;
            font-weight: bold;

            span {
              color: #ff98a0;
            }

            & + .gift-item {
              margin-top: 10px;
            }
          }

          .gift-item {
            color: #999;
            font-size: 12px;
            padding: 3px 0;

            span {
              width: 14px;
              height: 14px;
              color: #d2201f;
              text-align: center;
              line-height: 14px;
              margin-right: 5px;
              border: 1px solid #d2201f;
              display: inline-block;
              border-radius: 50%;
            }
          }

          p {
            margin: 5px 0 0;
            font-size: 11px;
            color: #999999;

            span {
              color: #ff98a0;
            }
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        .img-con {
          position: relative;
          width: 60px;
          height: 60px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
          }

          .the-price {
            position: absolute;
            right: -12px;
            top: -18px;
            width: 44px;
            height: 44px;
            background-image: url("../../assets/images/home/activity/price-bg.png");
            /*background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019141528767.png");*/
            background-size: 100% 100%;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .scale-font {
              -webkit-transform: scale(0.75);
              -moz-transform: scale(0.75);
              -ms-transform: scale(0.75);
              -o-transform: scale(0.75);
              transform: scale(0.75);
            }

            .second-line {
              margin-top: -3px;

              .scale-font {
                margin: 0 -3px 0 -2px;
              }
            }
          }
        }

        &.prompt {
          padding-top: 10px !important;
          justify-content: center;

          p {
            text-align: center;
          }
        }
      }

      .ivv-prompt {
        padding: 11px 0;
        border-bottom: solid 1px #f1f1f1;
      }

      .gift-detail {
        padding: 10px 15px 15px;
        box-sizing: border-box;
        background-color: #fffbf5;
        box-shadow: 0 0 8px #f2deb2 inset;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        //border: solid 3px #fff4dd;
        .gift-title {
          font-weight: bold;
          font-size: 16px;
          color: #ff98a0;
        }

        .gift-bag {
          position: relative;
          display: inline-block;

          .bag-title {
            height: 16px;
            line-height: 16px;
            padding: 0 7px;
            background-color: #ff98a0;
            text-align: center;
            border: solid 1.5px #fff;
            position: relative;
            font-size: 12px;
            color: #fff;
            z-index: 2;
            box-sizing: content-box;
          }

          span {
            position: absolute;
            top: 6px;
            width: 15px;
            height: 16px;
            background-color: #ff98a0;
            z-index: 1;
            color: #ffffff;
            font-size: 12px;

            &.left-span {
              left: -11px;

              &:before {
                left: 0;
                border-left: solid 5px #ffffff;
                border-right: solid 5px transparent;
              }
            }

            &.right-span {
              right: -11px;

              &:before {
                right: 0;
                border-left: solid 5px transparent;
                border-right: solid 5px #ffffff;
              }
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              height: 0;
              width: 0;
              border-top: solid 8px transparent;
              border-bottom: solid 8px transparent;
            }
          }
        }

        .gift-list {
          border-top: solid 1px #f2deb2;
          margin-top: 15px;
          padding-top: 9px;

          .bag-detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 22px;

            .left-con {
              font-size: 12px;
              color: #666666;
              display: flex;
              align-items: center;
              width: calc(100% - 50px);

              span {
                flex-shrink: 0;
                width: 15px;
                height: 15px;
                line-height: 15px;
                text-align: center;
                color: #fff;
                background-color: #ff98a0;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                margin-right: 10px;
              }
            }

            .bag-num {
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
    }

    .operate-area {
      .van-button {
        border: none;
        background-color: #8830cc;
      }
    }
  }

  &.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 120px;
  }
}

.double11 {
  border-radius: 40px 40px 6px 6px;
  height: 90%;
  background: #ffa8c6;

  .rules-cont {
    height: calc(100% - 10px);
    padding-top: 100px;
    background-color: transparent;

    .rules-content {
      .rules-detail {
        .subtitle {
          i {
            color: #fb69a5;
            font-style: normal;
          }

          span {
            display: inline-block;
            color: #333;
            position: relative;

            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 6px;
              width: 100%;
              background-color: rgba(225, 152, 160, 0.3);
            }
          }
        }
      }

      .exchange {
        .list-detail {
          text-align: left;
          display: block;
          padding: 11px 0 11px 20px;
          position: relative;
          border-bottom: none;

          .num {
            position: absolute;
            top: 11px;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            line-height: 16px;
            text-align: center;
            background-color: #fb69a5;
          }

          .b-item {
            .name {
              padding: 11px 0;
            }

            .txt {
              color: #999;
              position: relative;
              padding-left: 18px;
              line-height: 20px;

              i {
                font-style: normal;
                position: absolute;
                top: 0;
                left: 0;
              }

              span {
                color: #fb69a5;
              }
            }
          }
        }
      }

      .operate-area {
        padding: 0 30px 10px;

        .van-button {
          height: 33px;
          background: linear-gradient(to right, #fb69a5, #93a7f5);

          .van-icon-arrow {
            position: absolute;
            top: 8px;
            right: 15px;
          }
        }
      }
    }

    &.full-screen {
      padding-top: 116px;
    }
  }
}

.double12 {
  height: 90%;
  border-radius: 6px;
  background-color: #fedf08;

  .rules-cont {
    top: 8px;
    height: calc(100% - 10px);
    padding-top: 34%;
    background-color: transparent;

    .rules-content {
      .rules-detail {
        .subtitle {
          i {
            color: #f26452;
            font-style: normal;
          }

          span {
            color: #666;

            &:after {
              height: 6px;
              background-color: rgba(208, 152, 255, 0.3);
            }
          }
        }

        .list-detail {
          text-align: left;
          display: block;
          padding: 11px 0 11px 20px;
          position: relative;
          border-bottom: none;

          .num {
            position: absolute;
            top: 11px;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            line-height: 16px;
            text-align: center;
            background-color: #8830cc;
          }

          .b-item {
            .name {
              padding: 11px 0;
            }

            .txt {
              color: #999;
              position: relative;
              padding-left: 18px;
              line-height: 20px;

              i {
                font-style: normal;
                position: absolute;
                top: 0;
                left: 0;
              }

              span {
                color: #8830cc;
              }
            }
          }
        }
      }

      .operate-area {
        padding: 0 30px 10px;

        .van-button {
          height: 33px;

          .van-icon-arrow {
            position: absolute;
            top: 8px;
            right: 15px;
          }
        }
      }
    }

    &.full-screen {
      padding-top: 130px;
    }
  }
}

.new-year {
  border-radius: 6px;
  height: 90%;
  /*background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210317104252154.png");
        background-size: 120%;
        background-position: -30px 0;*/
  /*background: linear-gradient(to bottom, #C90000, #C90000);*/

  .rules-cont {
    height: calc(100% - 10px);
    padding-top: 90px;
    background-color: transparent;

    .rules-content {
      .rules-detail {
        .subtitle {
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: #ff99cc;
          }

          span {
            color: #000;
          }
        }

        .list-detail {
          position: relative;

          .img-con {
            .the-price {
              background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAABp1BMVEUAAADLAgP7VHXPCQ/NBwrPCAzMBQfiLT/PCAznL0H9YITdIjHXGCL5VnjvQlr7WXrSCA/TEBfnN0vySmfVFh/6WXvdIzDyS2fVFh/QCw/YGiXqO1LwRmH3U3T9XoPPCQ73U3L7W3/kMELlL0PPCAz7V3jfJjXcICv9W4D7UXPtMEXMBwr8XYH6W37OBwr8XIHpOVD+XYHNBgn7WHrzRWLfITHJAAHvR2PWGST2UnHRDxXqPFPqP1f3U3PPDhPWGSTwSGP7XIDpPFLdJjTLBAfsQVnaHyz4WHr1UnLyS2fVFR7uQ135WXvxSGT2UnHxSWXJAAD5WHrOCAvpOU7wSGTVGCHRDRLMBAfnNEjiLD7pNknLAAPKAADUDRfpOlDnLUXRDhX2UnHMBwvmM0fdIzHUExvWFyDpOU/fKDfnN0ziLT7vRmDYHCbuQ13kMUPaHirtQVnbIS3rPVTxSGTzTWryS2f2UnHSDxXOCAzgKzz3VXXgKjrQCxD5WHrpPFLiL0HeJjXXGiTrP1f1UW/0T23SERj4V3fQDhP7XH/MBQj6Wnz9XoLLAwSjM1bQAAAAY3RSTlMA/CaVSiase2oZ+KCVakxJMh/8+fnY1dTUuKqgm5iSeHZ1bGVZU05JMh4K262in11IPzs4NC328/Pe3tfTysrIx8TExMG/vri4s7OuraaUkY2Hh315eXNxWldQT0E3LhX39dITFbzOAAACvElEQVRYw7XRZVMbQQDG8SdKBEmAQoQYxHF3d3e3GqWlDi2FttQlNP3QvbvcRWZyk53d7e/VM3M3/9m7RUHe9sxu9YKZ1mJpVXaZxaIFq4Zk0hKCJJRMJhvAKJoUNYuzWZpR0Ah4Y/IaTUiEYklqjSIl5g2AXEMi0aiV/lpCFggoq0z6s43CGyB3549gISqPXHeA6EJqEIvfpEyv3uSxOi2POEi13hBpB6mSX0RKQMrznoin4ME8/jLpbgfJgoPSfZf5PWpH9V+KemeWLsncW5rplYZfJRi6pBRSCbZ/p9ShEjT/oHQMFRVvqFRATTVdsBpqZt9SmYWaZbrgMvKIlW4sVldcUKmoXtwojSFLzUj3BbPukZpM8CkXmaCWT1CLtPnnHMwjo4NHsANZJl8xm0S2UvZgC3IMfGM0gFzuc0bu3C/uP2fWXwrF0dgnLsaOIDK7T7lxm4H1U67W0VL+hKPyFmCvnGNvD4KdB9zsQFLHq1cHWS2fXi3Sil5wUIT/F6z9zEX6k+vOOJEvZfeMm10IwtcchYHI9QeOriMoesxVEWC28stZzRB1jn/hYrwTinDVQ2ZVYWSzsvasyFXFfEDkiLxmFkG2KfbgFLLYXnLQiQwXj6ALaYZnXNihsPIJFkNRPNzzlVnPsBRU2PWbLkflIyqVDtem3o48iumCxVAzRxecgxrHCRUH1FTSBSuhwnxC6VglePiT0qFKMPiOUlAl6JOe9jl1hJ27OmefNHwqQZNuS28Qx9BvIkMQGPRbOhMKaPpIRAdSJrKgCaTaroi0gVT8KsW5lq+z5pRHHMTqrwRGmzxy3QdsRnHUg5zxVtMk3bf+VubzKUsv3W2T5tYIcts6u7wmUhUTYEqtCaTYddugYfsr0ATFGdSI2wZGRqG3D8m+UDSClUHTdaBsfZfGAGYrbZl9sIJC/gHaDFqRq7XiwgAAAABJRU5ErkJggg==")
                no-repeat;
              background-size: 100% 100%;

              .second-line {
                margin-top: -2px;

                .scale-font {
                  display: inline-block;
                }
              }
            }
          }

          .mui-media-body {
            .gift-name {
              font-weight: normal;
            }

            > p {
              span {
                color: #ffa7d3;
              }

              .blue_words {
                color: #5e83de;
              }
            }
          }
        }
      }

      .operate-area {
        padding: 0 30px 10px;

        .van-button {
          height: 33px;

          .van-icon-arrow {
            position: absolute;
            top: 8px;
            right: 15px;
          }
        }
      }
    }

    &.full-screen {
      padding-top: 110px;
    }
  }
}

.new-year-mw {
  border-radius: 13px;
  height: 100%;

  .rules-cont {
    height: 100%;
  }

  .rules-content {
    .rules-detail {
      .subtitle {
        span {
          color: #66b4ff;
          position: relative;

          i {
            position: relative;
            z-index: 10;
          }

          &:after {
            content: "";
            position: absolute;
            left: -2px;
            right: -2px;
            height: 5px;
            bottom: 0;
            z-index: 5;
            border-radius: 5px;
            background-color: rgba(102, 180, 255, 0.5);
          }
        }
      }

      .list-detail {
        .mui-media-body {
          .gift-name,
          p {
            span {
              color: #66b4ff;
            }
          }
        }
      }
    }
  }
}

.recharge_fjs {
  /*border-radius: 6px;*/
  /*height: 90%;*/
  background-color: #b90022;
  border-radius: 40px 40px 6px 6px;

  .rules-cont {
    height: calc(100% - 10px);
    padding-top: 110px;
    background-color: transparent;

    .rules-content {
      .rules-detail {
        .subtitle {
          i {
            color: #d20027;
            font-style: normal;
          }

          span {
            color: #333;

            &:after {
              height: 6px;
              background-color: rgba(225, 152, 160, 0.3);
            }
          }
        }
      }

      .exchange {
        .list-detail {
          text-align: left;
          display: block;
          padding: 11px 0 11px 20px;
          position: relative;
          border-bottom: none;

          .num {
            position: absolute;
            top: 11px;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            line-height: 16px;
            text-align: center;
            background-color: #d20027;
          }

          .b-item {
            .name {
              padding: 11px 0;
            }

            .txt {
              color: #999;
              position: relative;
              padding-left: 18px;
              line-height: 20px;

              i {
                font-style: normal;
                position: absolute;
                top: 0;
                left: 0;
              }

              span {
                color: #d20027;
              }
            }
          }
        }
      }

      .operate-area {
        padding: 20px 30px 10px;

        .van-button {
          height: 33px;
          background: linear-gradient(to right, #fb69a5, #93a7f5);

          .van-icon-arrow {
            position: absolute;
            top: 8px;
            right: 15px;
          }
        }
      }
    }

    &.full-screen {
      padding-top: 116px;
    }
  }
}

.actDouble11 {
  border-radius: 40px 40px 6px 6px;
  height: 90%;
  .mui-media-body {
    .desc_color1 {
      color: #329af8;
    }

    .desc_color2 {
      color: #333;
      font-weight: bold;
    }
  }

  .rules-cont {
    height: 100%;
    background-repeat: repeat-y;
    .rules-content {
      .rules-detail {
        .subtitle {
          i {
            color: #329af8;
            font-style: normal;
          }

          span {
            display: inline-block;
            color: #333;
            position: relative;

            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 6px;
              width: 100%;
              background-color: rgba(152, 204, 251, 0.6);
            }
          }
        }

        .list-detail {
          .img-con {
            .the-price {
              /*background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20211019141528767.png") no-repeat;*/
              background-repeat: no-repeat;
              background-size: 100% 100%;
              color: #000;
              font-weight: bold;
            }
          }
        }
      }

      .exchange {
        .list-detail {
          text-align: left;
          display: block;
          padding: 11px 0 11px 20px;
          position: relative;
          border-bottom: none;

          .num {
            position: absolute;
            top: 11px;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            color: #fff;
            line-height: 16px;
            text-align: center;
            background-color: #a581ff;
          }

          .b-item {
            .name {
              padding: 11px 0;
            }

            .txt {
              color: #999;
              position: relative;
              padding-left: 18px;
              line-height: 20px;

              i {
                font-style: normal;
                position: absolute;
                top: 0;
                left: 0;
              }

              span {
                color: #fb69a5;
              }
            }
          }
        }
      }
    }

    &.full-screen {
       padding-top: 20px;
    }
  }
}
</style>