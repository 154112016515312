<template>
  <div class="rules-detail">
    <div v-for="(item,idx) in dataList" :key="idx">
      <activity-title :data-item="item"  v-if="item.title"/>
      <daily-item :data-list="item.list" :main-color="item.subColor" v-if="item.type === 1" />
      <graphic-item :data-item="item" v-else-if="item.type===2" />
      <text-item :data-item="item" v-else-if="item.type===3" />
      <div class="ivv-prompt" v-if="item.prompt" v-html="item.prompt" :style="'color:' + item.promptColor"></div>
      <div class="operate-area" v-if="item.exchangeLink">
        <van-button type="primary" block round @click="handleExchange(item.exchangeLink)" :color="item.btnColor">查看可兑换商品
          <van-icon name="arrow" />
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import DailyItem from "./DailyItem.vue"
import GraphicItem from "./GraphicItem.vue"
import ActivityTitle from "./ActivityTitle"
import TextItem from "./TextItem"
import { deliveryMessage } from '../../utils/appUtil'
export default {
  name: "RuleList",
  components: { DailyItem, GraphicItem, ActivityTitle, TextItem },
  props: {
    dataList: {
      type: Array,
      value: []
    },
    isInPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleExchange(path) {
      if (this.isInPage) {
        return this.$router.push({ path });
      }
      let message = { 'action': 'jumpExchangeZone' };
      deliveryMessage(message)
    }
  }
}
</script>
<style lang="scss" scoped>
.rules-detail {
  position: relative;
  overflow-y: auto;

  .subtitle_tips {
    font-size: 12px;
    color: rgb(254, 153, 161);
    text-align: left;

    &.font-gray {
      color: #999;
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .subtitle {
    font-size: 16px;
    padding-top: 10px;
    letter-spacing: 0.5px;

    i {
      font-style: normal;
    }

    span {
      // color: #ff98a0;
      position: relative;
    }
  }

  .ivv-prompt {
    padding: 11px 0;
    border-bottom: solid 1px #f1f1f1;
  }

  .gift-detail {
    padding: 10px 15px 15px;
    box-sizing: border-box;
    background-color: #fffbf5;
    box-shadow: 0 0 8px #f2deb2 inset;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    //border: solid 3px #fff4dd;
    .gift-title {
      font-weight: bold;
      font-size: 16px;
      color: #ff98a0;
    }

    .gift-bag {
      position: relative;
      display: inline-block;

      .bag-title {
        height: 16px;
        line-height: 16px;
        padding: 0 7px;
        background-color: #ff98a0;
        text-align: center;
        border: solid 1.5px #fff;
        position: relative;
        font-size: 12px;
        color: #fff;
        z-index: 2;
        box-sizing: content-box;
      }

      span {
        position: absolute;
        top: 6px;
        width: 15px;
        height: 16px;
        background-color: #ff98a0;
        z-index: 1;
        color: #ffffff;
        font-size: 12px;

        &.left-span {
          left: -11px;

          &:before {
            left: 0;
            border-left: solid 5px #ffffff;
            border-right: solid 5px transparent;
          }
        }

        &.right-span {
          right: -11px;

          &:before {
            right: 0;
            border-left: solid 5px transparent;
            border-right: solid 5px #ffffff;
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          height: 0;
          width: 0;
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
        }
      }
    }

    .gift-list {
      border-top: solid 1px #f2deb2;
      margin-top: 15px;
      padding-top: 9px;

      .bag-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22px;

        .left-con {
          font-size: 12px;
          color: #666666;
          display: flex;
          align-items: center;
          width: calc(100% - 50px);

          span {
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            color: #fff;
            background-color: #ff98a0;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        .bag-num {
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
  .operate-area {
    padding: 10px 10%;
    .van-button {
      height: 30px;
      .van-button__text {
        font-size: 14px;
        font-weight: bold;
      }
      .van-icon-arrow {
        position: absolute;
        top: 8px;
        right: 10%;
      }
    }
  }
}
</style>