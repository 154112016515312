<template>
  <div class="container" :class="{ 'double11': isInDoubleAct, 'mourning': isMourning }">
    <!-- 弹窗公告-->
    <div v-if="showTipsImg" class="tips_back_cover"></div>
    <div v-if="showTipsImg" class="tips_img_box">
      <div class="tips_img_content">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/2222/fd55a1a4f2c4443d81b5909be94bf7ad99.png" class="tips_img"
          alt="">
      </div>
      <span @click="showTipsImg = false" class="icon-delete-circle tips_img_box_icon"></span>
    </div>
    <div class="nav-fixed">
      <div class="nav-search">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230517155107274.png" />
        <!-- <div class="user-info" v-if="getUserShopInfo">
          <img :src="getUserShopInfo.headImg">
          <div class="user ellipsis1">
            <span>{{ getUserShopInfo.shopName }}</span>
            <div v-if="getUserShopInfo.shopPhone">
              电话:<input :value="getUserShopInfo.shopPhone" disabled />
              <button class="copy-btn" v-clipboard:copy="getUserShopInfo.shopPhone" v-clipboard:success="onCopy">复制
              </button>
            </div>
            <div v-else>
              微信:<input id="shopPhone" :value="'Xiangmizhengjie'" disabled />
              <button class="copy-btn" v-clipboard:copy="'Xiangmizhengjie'" v-clipboard:success="onCopy">
                复制
              </button>
            </div>
          </div>
        </div> -->
        <!-- <div class="cus-search">
          <span class="icon-search"></span>
          <div @click="jumpPage(3, '/Tool/html/search.html?index=1')">搜索</div>
        </div> -->
      </div>
    </div>
    <div class="mui-scroll">
      <div class="swipe-banner" v-if="sliderAllPicList.length > 0">
        <!--主页轮播图start-->
        <van-swipe @change="onSwipeChange" :autoplay="5000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in sliderAllPicList" :key="index" @click="jumpActPage(item)">
            <img src="../assets/images/home/home-00.png" v-lazy="item.show_img" />
            <div class="rule-share" v-show="item.shareBtnImage || item.ruleBtnImage" @click.stop="">
              <img v-if="item.shareBtnImage" @click="handleSwipeBtn('click_goto_params1')" :src="item.shareBtnImage" />
              <img v-if="item.ruleBtnImage" @click="handleSwipeBtn('click_goto_params2')" :src="item.ruleBtnImage" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!--主页轮播图end-->
      <!-- 活动列表-->
      <activity-config :activity-info="actAllInfo" />
      <activity :userIdentityV4="userIdentityV4" :user-identity="userIdentityV2" @open="openRuleLayer"
        @update="updateActeTime" />
      <!--二级菜单导航start-->
      <menu-nav :userIdentityV4="userIdentityV4" :user-info="userIdentityV2" />
      <!--二级菜单导航end-->
      <div class="data-manage">
        <div class="mi-head-line">
          <span class="h-title">蜜蛋头条</span>
          <ul class="mui-table-view">
            <span class="icon-notice"></span>
            <li v-for="(item, index) in headLines" :key="index" v-html="item.text" class="ellipsis1"
              :style="item.scrollCss" :class="{ 'animated': item.scrollCss }">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--首页推荐商品-->
    <home-recommend />
    <!--活动规则-->
    <div class="layer" @click="showRule = !showRule" v-if="showRule">
      <rule-v1 @close="closeRuleLayer" :is-in-page="true" :rule-index="ruleIndex" />
    </div>
    <!--底部导航-->
    <footer-tab :active="0" :user-identity="userIdentityNew" />
    <div class="drift-layer drift-layer-left">

      <!--    520充值-->
      <!-- <a href="javascript:" v-if="activityState.isExchangeMz" @click="jumpRoute('/Exchange?brandId=3')">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315114656469.png">
      </a> -->
      <!--大健康-->
      <a href="javascript:" v-if="activityState.isExchangeDjk || activityState.isExchangeMz"
        @click="jumpRoute('/Exchange')">
        <!-- <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221025165022431.png"> -->
        <img src="http://freesaasofficedata.oss.aliyuncs.com/mishop/20231020181601032.png">
      </a>
    </div>
    <div class="drift-layer">
    </div>
    <!--蜜豆签到-->
    <div class="sign-dou" v-if="signMessage">
      <div class="mi-box">
        <div class="close-layer" @click="signMessage = null">
          <span class="icon-delete"></span>
        </div>
        <div class="content">
          <div class="message">
            <span>签到成功,已赠送您蜜豆</span><br />
            <span class="mi-count">{{ signMessage.playDou }}</span>
            <img src="../assets/images/home/mi_dou.png" />
            <template v-if="signMessage.addDou > 0">
              <br /><i>（连续签到7天,额外赠送{{ signMessage.addDou }}蜜豆)</i>
            </template>
          </div>
          <img src="../assets/images/home/line_01.png" />
          <div class="inspirational" v-html="signMessage.Soul"></div>
        </div>
      </div>
    </div>
    <!--开店弹窗-->
    <shop-dialog :index="shopDialogIndex" v-if="showShopDialogLayer" @close="showShopDialogLayer = false" />
    <shop-dialog :index="11" v-if="showExchangeDialogLayer" @close="showExchangeDialogLayer = false" />
    <van-overlay v-show="signMessage" :index="0" />

  </div>
</template>
<script>
import { formatTime } from "../utils/formatTime"
import HomeRecommend from "../components/Home/HomeRecommend";
import Activity from "../components/Home/Activity";
import ActivityConfig from "../components/Home/ActivityConfig" //后台配置活动
import FooterTab from "../components/Footer";
import defaultMedia from "../assets/images/100.png";
import Rule from "../components/Home/Rule"
import RuleV1 from "../components/Regulation/Index"
import ShopDialog from "../components/Home/ShopDialog";
import menuNav from "../components/Home/Nav"
import storage from "../store/storage"

let headLinesTimer = null; //蜜蛋头条定时器
let scrollHNum = 0;  //蜜蛋头条滚动的个数
export default {
  name: 'Home',
  components: {
    HomeRecommend, Activity, FooterTab, Rule, RuleV1, ShopDialog, menuNav, ActivityConfig
  },
  data() {
    return {
      topSwipeIndex: 0,//顶部轮播索引
      userIdentity: null,  // 用户身份信息
      userIdentityNew: 1,  // 1新人 2 专卖店主 3 商城店主 4 旗舰店主
      minUserIdentityNew: 1,
      userIdentityV2: null,
      signMessage: null,//蜜豆签到信息
      miAccount: null,  //蜜豆账户余额
      customIndex: 0,
      sliderAllPicList: [], //所有身份轮播图集合
      isShowActRuleBtn: 0, //头部活动是否显示规则和分享按钮  0 不显示。1 不显示规则 2 不显示分享  3显示全部
      headLines: [],//蜜蛋头条列表
      showRule: false,
      ruleIndex: 0,
      scrollFixed: false, //是否固定页面
      shopDialogIndex: -1,// 开店弹框内容索引
      showShopDialogLayer: false, // 显示开店弹窗
      showTipsImg: false,
      isInDoubleAct: false,// 是否在双11活动中
      activityState: {},
      showExchangeDialogLayer: false,
      userIdentityV4: -1, //新人-1 超V 0 代理1 取消授权2
      isMourning: false,//是否开启悼念模式
      actAllInfo: null,
    }
  },
  created() {
    let mourning = storage.get("rl-mourning");
    this.isMourning = mourning === '1';
    this.getConfigList();
    this.getUserAgentInfo();
    // this.getUserIdentityV2();
    this.getHeadLines();
    this.getExchangeBtnState();
    this.getUserIdentityV4();
  },
  methods: {
    onSwipeChange(i) {
      this.topSwipeIndex = i;
      this.calcBannerBtnType(i);
    },
    //点击顶部活动图的按钮
    handleSwipeBtn(key) {
      let item = this.sliderAllPicList[this.topSwipeIndex];
      window.location.href = item[key];
    },
    isShowTipsImg() {
      if (this.userIdentityNew != 1 && !sessionStorage.getItem('isShow')) {
        this.showTipsImg = true
        sessionStorage.setItem('isShow', 1)
      }
    },
    //获取用户代理信息
    getUserAgentInfo() {
      let list = window.sessionStorage.userIdentity;
      if (list) {
        let agentList = JSON.parse(list);
        if (agentList && agentList.length > 0) {
          return this.userIdentity = agentList;
        }
      }
      this.get("/MemberAgent/Info/MemberAgentInfo?brandid=-1").then(json => {
        if (json.code === 1) {
          this.userIdentity = json.response;
          window.sessionStorage.userIdentity = JSON.stringify(json.response);
        }
      })
    },
    //获取兑换专区入口按钮显示情况
    getExchangeBtnState() {
      this.get("/OrderGoods/ActivityOther/GetActivityStateV2", {}, 2).then(json => {
        if (json.code === 1) {
          let data = json.response;
          this.activityState = data
        }
      })
    },
    //复制手机号
    onCopy() {
      this.$toast("复制成功");
    },
    //二级菜单导航切换
    menuOnChange(index) {
      this.customIndex = index;
    },
    //获取用户身份
    getUserIdentityV4: function () {
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V4").then(json => {
        if (json.code === 1) {
          //0：待审核，1：已审核， 3：未建立经销体系 4：黑名单，5：取消授权
          // userIdentityV4  新人-1 超V 0 代理1 取消授权2
          let { mzState, jkState, jkLevel, mzLevel } = json.response;
          // || (jkLevel < 3 && mzLevel < 3))
          if (jkLevel > 2 || mzLevel > 2) {
            this.userIdentityV4 = ([4, 5].indexOf(jkState) >= 0 || [4, 5].indexOf(mzState) >= 0) ? 2 : 1;
          } else {
            this.userIdentityV4 = (jkLevel === 2 || mzLevel === 2) ? 0 : -1;
          }
          console.log(this.userIdentityV4)
          let maxLevel = Math.max(mzLevel, jkLevel);
          let minLevel = Math.min(mzLevel, jkLevel);
          this.userIdentityNew = maxLevel;
          this.minUserIdentityNew = minLevel;
          this.isShowTipsImg()
          this.getSliderPic();
          this.userIdentityV2 = json.response;
          if (maxLevel === 1) {
            this.getMiDouBySign();
          }
        }
      })
    },
    //蜜豆签到
    getMiDouBySign() {
      let signTime = window.localStorage.SignTime || null;
      let nowTime = formatTime(new Date(), 'YY-MM-DD');
      if (signTime) {
        signTime = signTime.replace(/\-/g, "/");
        nowTime = nowTime.replace(/\-/g, "/");
        if (+new Date(signTime) === +new Date(nowTime)) {
          return;
        }
      }
      this.post("/MemberAgent/Info/PlayInterGral", {
        type: 1001
      }).then(json => {
        if (json.code === 1) {
          this.signMessage = json.response;
          window.localStorage.SignTime = nowTime;
          this.miAccount = {
            integralCount: json.response.totalDou
          }
        }
      });
    },
    //获取轮播图
    getSliderPic() {
      this.get('/Groupbuy/Product/GetHomeActivityApp', {
        category: this.userIdentityNew
      }, 2).then(json => {
        if (json.code === 1) {
          let { topActivity } = json.response;
          topActivity.forEach(item => {
            item.shareBtnImage = item.goto_params1 ? item.goto_params1.bgImg : '';
            item.ruleBtnImage = item.goto_params2 ? item.goto_params2.bgImg : '';
          })
          this.sliderAllPicList = topActivity;
          this.actAllInfo = json.response;
          this.calcBannerBtnType(0);
        }
      });
    },
    calcBannerBtnType(i) {
      let topActivity = this.sliderAllPicList;
      let isShowRule = false, isShowShare = false;
      if (topActivity.length > i) {
        isShowShare = !!topActivity[i].click_goto_params1;
        isShowRule = !!topActivity[i].click_goto_params2;
      }
      //0 不显示。1 不显示规则 2 不显示分享  3显示全部
      if (isShowRule && isShowShare) {
        this.isShowActRuleBtn = 3;
      }
      else if (isShowRule) this.isShowActRuleBtn = 1;
      else if (isShowShare) this.isShowActRuleBtn = 2;
      else this.isShowActRuleBtn = 0;
    },
    //获取蜜蛋头条内容
    getHeadLines() {
      this.get("/GroupBuy/Product/GetNewMiAndAgentUser", {}, 2).then(json => {
        let array = [];
        if (json.code === 1) {
          let data = json.response;
          let headLines = "\<span class='pd-left'>已有</span><span class='count'>" + data.userCou + "\</span><span>人选择蜜蛋，一起加入我们吧！</span>";
          let notice = "";
          if (data.Notice.length > 0) {
            notice = "\<span class='pd-left'>" + data.Notice[0].ArticleTitle + "</span>";
          }
          for (let i = 0; i < data.newMiUser.length; i++) {
            if (i % 5 === 0) {
              array.push({ text: notice, scrollCss: "" });
              array.push({ text: headLines, scrollCss: "" });
            }
            array.push({
              text: "\<span class='pd-left'>" + data.newMiUser[i].name + "\</span>",
              scrollCss: ""
            });
          }
        }
        if (array.length > 0) {
          this.letHeadLinesScroll(array);
        }
      });

    },
    letHeadLinesScroll(list) {
      for (let i = 0; i < list.length; i++) {
        if (i === 0) {
          list[i].scrollCss = this.getScrollCss(1, 44);
        } else {
          list[i].scrollCss = "";
        }
      }
      this.headLines = list;
      headLinesTimer = setInterval(() => {
        let num = scrollHNum + 1;
        let list = this.headLines;
        for (let i = 0; i < list.length; i++) {
          if (i + 1 === num) {
            list[i].scrollCss = this.getScrollCss(2, 44);
          } else if (i === num) {
            list[i].scrollCss = this.getScrollCss(1, 44);
          } else {
            list[i].scrollCss = "";
          }
        }
        if (num === list.length) {
          num = 0;
          list[0].scrollCss = this.getScrollCss(1, 44);
        }
        scrollHNum = num;
        this.headLines = list;
      }, 3000);
    },
    getScrollCss(num, h) {
      return "transform: translate(0,-" + num * h + "px)";
    },
    jumpPage: function (type, url, id) {
      window.location.href = (type === 1 ? this.userHost : (this.baseHost + '/7999')) + url;
    },
    jumpRoute(url) {
      this.$router.push(url)
    },
    jumpActPage(item) {
      let url = item.gzh_link;
      if (!url && url === null) return;
      if (item.level_min > 1 && this.userIdentityNew < 2) return this.$dialog.confirm({
        title: '提示',
        message: '您暂未开启专属店铺,如需采购请先开店噢!',
        confirmButtonText: '注册店主'
      }).then(() => {
        this.$router.push("/apply/green");
      }).catch(() => {
      });
      window.location.href = url;
    },
    //打开规则弹窗
    openRuleLayer(item) {
      this.showRule = true;
      this.ruleIndex = item.ruleIndex;
      this.scrollFixed = true;
    },
    //关闭规则
    closeRuleLayer() {
      this.showRule = false
      this.scrollFixed = false;
    },
    //更新活动时间
    updateActeTime(list) {
      list.forEach(item => {
        if (item.ModuleKEY === 'gzhDH1120201012') {
          this.isInDoubleAct = item.IsShow;
        }
        if (item.ModuleKEY === 'gzhDJK12DH20201202') {
          //  this.isInDoubleExchange = item.IsShow;
        }
      })
    },
    //获取配置信息
    getConfigList: function () {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get('/Base/ConfigAddress/GetWebInformationList?', {
        type: "73"
      }, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          if (!json.response.length) {
            this.isMourning = false;
            storage.set("rl-mourning", '');
          }
          json.response.find(it => {
            if (it.typeId === 73) {
              this.isMourning = it.url === "1";
              storage.set("rl-mourning", it.url);
              return true
            }
            return false
          })
        }
      }, 3);
    }
  },
  computed: {
    getUserShopInfo: function () {
      let list = this.userIdentity;
      if (list && list.length > 0) {
        let headUrl = "", shopName = "", phone = "";
        for (let i = 0; i < list.length; i++) {
          if (list[i].IsAgent || list[i].IsLowerAgent) {
            if (!headUrl) {
              headUrl = list[i].HeadUrl;
            }
            if (!shopName) {
              shopName = list[i].AgentName + (this.myLevel === 2 ? '的蜜蛋超V' : (this.myLevel === 3 ? '的蜜蛋旗舰店' : ''));
            }
            if (!phone) {
              phone = list[i].agencyPhone;
            }
            if (headUrl && shopName && phone) {
              break;
            }
          }
        }
        return {
          headImg: headUrl ? headUrl : defaultMedia,
          shopName: shopName ? shopName : '蜜蛋连锁店铺系统',
          shopPhone: phone ? phone : ''
        };
      }
      return null;
    }
  }
}
</script>
<style lang="scss">
@mixin sprite-icon1($x, $y) {
  background-position: $x $y;
}



.container {
  text-align: left;


  .tips_back_cover {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 998;
  }

  .tips_img_box {
    /*height: 525px;*/
    /*width: 345px;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tips_img_content {
      width: 80%;
      border-radius: 6px;
      max-height: 70%;
      position: relative;
      overflow-y: auto;

      img {
        width: 100%;
      }
    }

    .tips_img_box_icon {
      width: 30px;
      height: 30px;
      font-size: 25px;
      color: #fff;
      z-index: 1;
      margin-top: 20px;
      transform: translateX(50%) translateY(-50%);
    }
  }

  .nav-fixed {
    position: fixed;
    top: 0;
    z-index: 666;
    border: none;
    border-radius: 0;
    background-color: #fff;
    width: 100%;

    .nav-search {
      height: 36px;
      padding: 10px 15px 0;

      img {
        height: 30px;
      }

      .user-info {
        display: flex;
        padding: 1px;
        position: relative;
        border-radius: 38px 0 0 38px;
        max-width: calc(100% - 100px);
        background: linear-gradient(to right,
            #ffbec3,
            #ffbec3,
            rgba(255, 255, 255, 0));
        background: -webkit-linear-gradient(to right,
            #ffbec3,
            #ffbec3,
            rgba(255, 255, 255, 0));
        background: -o-linear-gradient(to right,
            #ffbec3,
            #ffbec3,
            rgba(255, 255, 255, 0));
        background: -moz-linear-gradient(to right,
            #ffbec3,
            #ffbec3,
            rgba(255, 255, 255, 0));

        .user {
          color: #fff;
          text-align: left;

          >span {
            font-size: 13px;
          }

          div {
            font-size: 10px;
            line-height: 12px;

            input {
              width: 80px;
              border: none;
              font-size: 10px;
              line-height: 12px;
              display: inline-block;
              background-color: transparent;

              &[disabled] {
                opacity: 1;
              }
            }

            .copy-btn {
              color: #b17500;
              font-size: 9px;
              padding: 2px 5px;
              margin-left: 6px;
              line-height: 8px;
              border: none;
              border-radius: 14px;
              display: inline-block;
              background-color: #ffee9e;
            }
          }
        }

        img {
          width: 35px;
          height: 35px;
          margin-left: 1px;
          margin-right: 6px;
          border-radius: 50%;
        }
      }

      .cus-search {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 0;
        text-align: center;

        .icon-search {
          color: #666;
          font-size: 18px;
        }

        >div {
          color: #666;
          font-size: 11px;
        }
      }
    }

    .head-nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0;
      padding: 0 15px;

      a {
        text-align: center;
        display: inline-block;
        width: 33.333%;
        padding: 7px 0;

        span {
          font-size: 14px;
          color: #999;
        }

        &.md-active {
          position: relative;

          span {
            color: #666;
          }

          &:after {
            content: '';
            position: absolute;
            left: 34%;
            right: 34%;
            bottom: 0;
            width: 30px;
            height: 2px;
            background-color: #ff98a0;
          }
        }
      }
    }
  }

  .mui-scroll {
    background: #fff;
    padding: 48px 15px 20px;

    .swipe-banner {
      position: relative;

      .van-swipe-item {
        font-size: 0;

        img {
          max-width: 100%;
        }

        border-radius: 6px 6px 0 0;
      }

      .van-swipe__indicators {
        bottom: 50px;

        .van-swipe__indicator {
          width: 9px;
          height: 2px;
          border-radius: 0;

          &.van-swipe__indicator--active {
            background-color: #fff;
            opacity: 1;
          }
        }
      }
    }

    .rule-share {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 11px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($color: #000000, $alpha: 0.2);

      img {
        width: 76px;

        &:nth-child(2) {
          margin-left: 20%;
        }
      }
    }

    .sub-menu-box {
      padding-top: 6px;

      .mui-slider-item {
        font-size: 0;
        padding: 10px 0;
      }

      a {
        width: 20%;

        &:not(.mui-control-item) {
          display: inline-block;
          text-align: center;
        }

        span {
          font-size: 14px;
          color: #fff;
          width: 45px;
          height: 45px;
          padding-top: 3px;
          display: inline-block;
          box-sizing: border-box;
          background: url('../assets/images/home/home_sprite.png') no-repeat;
          background-size: 225px 135px;

          &.icon1 {
            @include sprite-icon1(0, 0);
          }

          &.icon2 {
            @include sprite-icon1(-45px, 0);
          }

          &.icon3 {
            @include sprite-icon1(-90px, 0);
          }

          &.icon4 {
            @include sprite-icon1(-180px, -45px);
          }

          &.icon5 {
            @include sprite-icon1(-135px, 0);
          }

          &.icon6 {
            @include sprite-icon1(-180px, 0);
          }

          &.icon7 {
            @include sprite-icon1(-135px, -45px);
          }

          &.icon8 {
            @include sprite-icon1(0, -45px);
          }

          &.icon9 {
            @include sprite-icon1(-90px, -45px);
          }

          &.icon10 {
            @include sprite-icon1(-45px, -45px);
          }

          &.icon12 {
            background: url(../assets/images/home/Customer.png) no-repeat;
            background-size: 45px 45px;
          }

          &.icon13 {
            background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20210513165425462.png');
            background-size: 45px 45px;
          }
        }

        p {
          font-size: 11px;
          color: #999;
          padding-top: 10px;
        }
      }
    }

    .custom-slider-indicator {
      text-align: center;

      .custom-indicator {
        width: 20px;
        height: 2px;
        display: inline-block;
        background: #e5e5e5;

        &.custom-active {
          background: #ff98a0;
          border-radius: 6px;
        }
      }
    }

    .ivv-home-grid {
      .van-grid-item {
        .van-grid-item__icon-wrapper {
          span {
            font-size: 26px;
          }

          img {
            width: 38px;
            margin-top: -8px;
          }
        }

        .van-grid-item__text {
          color: #999;
          font-size: 12px;
          padding-top: 6px;
        }

        &.special {
          .van-grid-item__text {
            padding-top: 0;
            margin-top: -3px;
            position: relative;
            z-index: 10;
          }
        }
      }
    }

    .store-item-box {
      padding-bottom: 10px;

      .van-row {
        .van-col {
          position: relative;

          img {
            width: 100%;
            /*height: 90px;*/
          }

          .go-btn {
            position: absolute;
            left: 11px;
            bottom: 11px;
            color: #ff99a1;
            font-size: 16px;
            padding: 2px 5px 0;
            line-height: 16px;
            border-radius: 18px;
            background-color: #fff;

            .icon-arrow-right {
              font-size: 12px;
            }

            span {
              display: inline-block;
              vertical-align: middle;
            }
          }

          .ivv-item {
            position: relative;

            .go-btn {
              left: initial;
              right: 3px;
              padding: 2px 3px;
              letter-spacing: -2px;
              bottom: calc(50% - 9px);
            }

            &:nth-child(2) {
              padding-top: 2px;
            }
          }
        }
      }
    }

    .data-manage {
      border-radius: 6px;
      text-align: left;

      .mi-head-line {
        height: 34px;
        position: relative;
        padding: 5px 10px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #e5e5e5;

        span {
          color: #999;
        }

        .h-title {
          color: #ff98a0;
          font-size: 14px;
          font-weight: bold;
        }

        .icon-notice {
          color: #ff98a0;
          font-size: 14px;
          position: relative;
          left: 5px;
          top: 8px;
          padding-left: 2px;
        }

        .mui-table-view {
          position: absolute;
          top: 0;
          left: 72px;
          right: 10px;
          height: 32px;
          z-index: 0;
          overflow: hidden;
          background: #fff;

          &:after {
            height: 0;
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            top: 8px;
            bottom: 8px;
            background-color: #e5e5e5;
            height: auto;
          }

          li {
            position: absolute;
            top: 44px;
            left: 15px;
            right: 0;
            height: 100%;
            padding: 7px 0;

            &.animated {
              transition: all 0.3s linear;
            }

            p {
              color: #999;
              font-size: 12px;
              overflow: hidden;
              margin-bottom: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
            }
          }
        }

        .pd-left {
          padding-left: 8px;
        }

        .count {
          color: #ff98a0;
          padding: 0 2px;
          font-weight: bold;
        }
      }

      .shop-kits {
        background: linear-gradient(#ecdbb6, #f3e3c6);
        padding: 6px 10px;
        border-radius: 3px;

        span {
          &.icon--11 {
            color: #91744d;
          }

          &.kits {
            font-size: 14px;
            color: #91744d;
            padding-left: 10px;
          }

          &.open {
            font-size: 14px;
            color: #91744d;
          }

          &.look {
            font-size: 12px;
            color: #fff;
            background: #91744d;
            position: relative;
            padding: 1px 15px 1px 12px;
            border-radius: 20px;
            float: right;
            margin-top: 4px;

            &:before {
              content: '';
              position: absolute;
              top: 7px;
              right: 6px;
              width: 6px;
              height: 6px;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  .layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 10% 30px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;

    .rules-cont {
      border-radius: 12px;
    }
  }

  /*蜜豆签到*/
  .sign-dou {
    position: fixed;
    top: 20%;
    left: 10%;
    z-index: 999;
    text-align: center;

    .mi-box {
      position: relative;
      width: 296px;
      height: 402px;
      z-index: 1000;
      padding-top: 110px;
      box-sizing: border-box;
      background: url('../assets/images/home/mi_dou_bg.png') no-repeat;
      background-size: 100% 100%;

      .close-layer {
        position: absolute;
        left: calc(50% - 35px / 2);
        bottom: -50px;
        width: 35px;
        height: 35px;
        z-index: 11;
        border-radius: 50%;
        text-align: center;
        border: 2px solid #fff;

        .icon-delete {
          color: #fff;
          font-size: 24px;
          margin-top: 4px;
          display: inline-block;
        }
      }

      .content {
        font-size: 0;
        padding: 0 15px;
        position: relative;

        .message {
          padding: 17px 20px 6px;
          text-align: center;
          border-radius: 8px 8px 0 0;
          background-color: #fff;

          span {
            color: #f18e98;
            font-size: 18px;
          }

          .mi-count {
            color: #f18e98;
            font-size: 35px;
            font-weight: bold;
          }

          img {
            max-height: 19px;
            margin-left: 6px;
            display: inline-block;
            vertical-align: text-bottom;
          }

          i {
            color: #f18e98;
            font-size: 13px;
          }
        }

        >img {
          max-width: 100%;
        }

        .inspirational {
          color: #f18e98;
          font-size: 12px;
          padding-bottom: 10px;
          background-color: #fff;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 122px;
        z-index: 10;
        background: url('../assets/images/home/mi_dou_box.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .icon-delete {
      &:before {
        content: '\e94c';
      }
    }
  }

  .drift-layer {
    position: fixed;
    right: 3%;
    bottom: 100px;
    z-index: 1000;
    max-width: 56px;

    a {
      display: inline-block;
      margin-top: 5px;
      position: relative;
      animation: mymove 5s infinite;

      img {
        max-width: 100%;
      }
      .van-icon-close{
        position: absolute;
        top:-6px;
        right: -6px;
      }

    }

    &.multi {
      bottom: 210px;
    }
  }

  .drift-layer-left {
    position: fixed;
    left: 3%;
    bottom: 160px;
    z-index: 1000;
    max-width: 56px;
  }

}

.mourning {

  .nav-fixed,
  .tips_img_box,
  .mui-scroll,
  .brand-list,
  .drift-layer,
  .van-tabbar {
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%);
  }
}

.double11 {
  .nav-fixed {
    background: linear-gradient(to bottom, #9a1eec, #6d38ea);
    background: -ms-linear-gradient(to bottom, #9a1eec, #6d38ea);
    background: -webkit-linear-gradient(to bottom, #9a1eec, #6d38ea);

    .nav-search {
      .cus-search {
        .icon-search {
          color: #fff;
        }

        >div {
          color: #fff;
        }
      }
    }

    .head-nav {
      a {
        span {
          color: #fff;
        }

        &.md-active {
          span {
            color: #fff;
          }
        }
      }
    }
  }

  .mui-scroll {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
      height: 79px;
      background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20201021173435220.png') no-repeat;
      background-size: 100% 100%;
    }

    .sub-menu-box {
      a {
        span {
          background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20201021181332798.png');
          background-size: 225px 135px;

          &.icon12 {
            background: url('http://freesaasofficedata.oss.aliyuncs.com/miShop/20201021181332798.png') -180px 0;
            background-size: 225px 135px;
          }
        }
      }
    }
  }
}

.van-overlay {
  z-index: 667;
}

@media screen and (max-width: 350px) {
  .container {
    .mui-scroll {
      padding: 58px 5px 20px;

      .ivv-home-grid {
        .van-grid-item__content {
          padding: 10px 4px;
        }
      }
    }
  }
}
</style>
