<template>
  <div class="container">
    <div class="cus-header">
      <div class="title">
        {{ (type === 0) ? (agentLevel > 3 ? '旗舰店铺' : '超V') : '可查看超V' }}<span>{{ chainTotal }}</span>
      </div>
    </div>
    <div class="cus-content" v-if="!type && agentLevel > 3">
      <div class="sub-header">
        <div class="s-item" :class="{ 'active': tabIndex === 1 }" @click="changeShopType(1)">
          <span>连锁店总数</span>
          <van-icon name="down" :class="{ 'reverse': sortTotal === 2 }" @click.prevent="handleSortBy(1)" />
        </div>
        <div class="s-item" :class="{ 'active': tabIndex === 2 }" @click="changeShopType(2)">
          <span>已失效连锁店铺</span>
          <van-icon name="down" :class="{ 'reverse': sortLess === 2 }" @click.prevent="handleSortBy(2)" />
        </div>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="" @load="getChainStoreInfo">
      <ManageUserList :finished="finished" :user-list="agentList" :agent-level="agentLevel" @change="viewOtherAgent" />
    </van-list>
  </div>
</template>

<script>
import ManageUserList from "../../components/ManageUserList";

export default {
  name: "FlagshipList",
  data() {
    return {
      loading: false,
      finished: false,
      type: 0, //0旗舰店 1 超V
      tabIndex: 1, //1 连锁店铺 2、失效店铺
      chainTotal: 0, //店铺总数
      agentLevel: 1, //级别id
      agentId: 0,
      agentList: [],
      sortTotal: 1, //1 正序 2 倒序
      sortLess: 1,
      isGold: 0, //是否是皇冠
      pg: 0,
      ps: 10
    }
  },
  components: {
    ManageUserList
  },
  created() {
    this.initParams(this.$route.query);
  },
  methods: {
    initParams(params) {
      this.agentId = params.id;
      this.isGold = params.gold;
      this.agentLevel = +params.level;
      this.type = +params.type;
    },
    //切换店铺类型
    changeShopType(type) {
      if (type === this.tabIndex) return;
      if (this.loading) return;
      this.tabIndex = type;
      this.finished = true;
      this.chainTotal = 0;
      setTimeout(() => {
        this.loading = false;
        this.finished = false;
        this.pg = 0;
        this.agentList = [];
      }, 10);
    },
    //排序查询数据
    handleSortBy(type) {
      if (this.tabIndex !== type) return
      if (type === 1) {
        this.sortTotal = this.sortTotal === 1 ? 2 : 1;
      } else {
        this.sortLess = this.sortLess === 1 ? 2 : 1;
      }
      this.chainTotal = 0;
      this.finished = true;
      setTimeout(() => {
        this.loading = false;
        this.finished = false;
        this.pg = 0;
        this.agentList = [];
      }, 10);
    },
    //获取连锁店详细列表
    getChainStoreInfo: function () {
      this.pg++;
      let path = "/TeamAgent/ChainStore/GetTeamListCv";
      let params = { agentId: this.agentId, pageIndex: this.pg, pageSize: this.ps }
      if (this.type !== 1) {
        path = '/TeamAgent/ChainStore/GetTeamList';
        params.typeSort = this.tabIndex;
        params.isGold = this.isGold;
        params.orderBy = this.tabIndex === 1 ? this.sortTotal : this.sortLess;
      }
      this.post(path, params).then(json => {
        this.loading = false;
        if (json.code === 1) {
          let list = json.response || [];
          let hisList = this.agentList || [];
          this.agentList = hisList.concat(list);
          if (list.length < 10) {
            this.finished = true;
          }
          if (this.pg === 1) {
            this.chainTotal = json.total;
          }
        } else {
          this.finished = true;
        }
      })
    },
    //查看其它代理
    viewOtherAgent(item) {
      this.$router.push({
        name: "ShopManage",
        params: { "id": item.agentId }
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.initParams(to.params);
      this.agentList = null;
      this.loading = false;
      this.finished = false;
      this.pg = 0;
    }
    next();
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: #F8F8F8;
}

.cus-header {
  overflow: hidden;
  padding: 20px 0 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;


  .title {
    color: #FF98A0;

    span {
      font-size: 28px;
      padding-left: 5px;
    }
  }

  .chain-total {

    font-size: 28px;
    padding-top: 10px;
  }
}

.cus-content {
  margin: 0 15px;
  background-color: #fff;

  .sub-header {
    display: flex;
    background-color: #FCFCFC;

    .s-item {
      flex: 1;
      color: #999;
      font-size: 12px;
      line-height: 40px;
      position: relative;

      .van-icon-down {
        padding: 2px 5px;
        font-size: 12px;
        display: inline-block;
        vertical-align: text-bottom;
      }

      .reverse {
        transform: rotate(180deg);
      }

      &.active {
        color: #FF98A0;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 30px;
          height: 2px;
          margin-left: -15px;
          border-radius: 1px;
          background-color: #FF98A0;
        }
      }
    }
  }

  &+.van-list {
    top: 130px;
  }
}

.van-list {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px;
  overflow: auto;
  background-color: #F8F8F8;

  .user-list {
    padding: 11px;
    background-color: #fff;

  }
}
</style>