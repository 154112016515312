import { post } from "./axios";
import { setCookie } from "./cookies";

const deliveryMessage = (message) => {
  var u = navigator.userAgent;
  if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    window.webkit.messageHandlers.jsCallAppMethod.postMessage(message);
  }
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    window.WebViewJavascriptBridge.callHandler('JSCallAndroid', message);
  }
}
const replaceAppToken = (appToken, callback) => {
  post("/Base/IdToken/ReplaceAPPToken", {
    apptoken: appToken
  }).then(json => {
    if (json.code === 1) {
      let res = json.response;
      if (res.canReplace) {
        setCookie("E", res.e);
        setCookie("token", res.token);
      }
    }
    if (callback) callback();
  });
}
const isIos = () => {
  let u = navigator.userAgent;
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isiOS;
}
const openMdApp = (baseHost, shareUserId) => {
  //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值
  let u = navigator.userAgent;
  let url = baseHost + '/7999/Redesign/html/applyStore/appDownload.html?shareUserId=' + shareUserId + '&appStore=';
  //Android终端
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  //iOS终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // return alert('是不是:' + isAndroid);
  if (isAndroid) {
    //如果为Android
    return window.location = url + 1
  }
  if (isiOS) {
    //如果为iOS
    window.location = url + 2
  }
}


export {
  deliveryMessage, replaceAppToken, openMdApp,isIos
}
