<template>
  <div class="grapic-box" :style="styleVar">
    <div class="txt-item" v-for="(imgUrl,index) in dataItem.list" :key="index">
      <img :src="imgUrl" />
    </div>
  </div>
</template>
<script>
// 图文规则
export default {
  name: 'GraphicItem',
  props: {
    dataItem: {
      type: Object,
      value() {
        return {}
      }
    }
  },
  data() {
    return {
      styleVar: {
        "--color": this.dataItem.subColor
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.grapic-box {
  padding-top: 10px;
  /deep/ .txt-item {
    line-height: 22px;
    span {
      color: var(--color);
    }
    img {
      width: 100%;
    }
    &:first-child {
      img {
        padding-top: 0;
      }
    }
  }
}
</style>