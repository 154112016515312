<template>
  <van-tabbar v-model="tabIndex">
    <van-tabbar-item to="/">
      <span :class="tabIndex === 0 ? 'icon-home-active' : 'icon-home'" />
      <p>主页</p>
    </van-tabbar-item>
    <!-- /Tool/html/index.html? -->
    <van-tabbar-item @click="jumpPage(3,'/Arongleweb/html/groupon/groupon_list.html')">
      <span :class="tabIndex === 1 ? 'icon-purchase' : 'icon-purchase-line'" />
      <p>订货</p>
    </van-tabbar-item>
    <van-tabbar-item @click="jumpPage(3,'/Arongleweb/html/groupon/shop_cart.html')">
      <span :class="tabIndex === 2 ? 'icon-cart-active' : 'icon-cart'" />
      <p>购物车</p>
    </van-tabbar-item>
    <van-tabbar-item @click="jumpPage(3,'/Arongleweb/html/mycenter/manage.html')">
      <span :class="tabIndex === 3 ? 'icon-mine-active' : 'icon-mine'" />
      <p>我的</p>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "Footer",
  props: {
    active: {
      type: Number,
      value: 0
    },
    userIdentity: {
      type: Number,
      value: 1
    }
  },
  data() {
    return {
      tabIndex: this.active
    }
  },
  methods: {
    jumpPage: function (type, url) {
      if (type === 1 || type === 3) {
        if (!url) {
          this.tabIndex = 0;
          return this.$toast('升级中，敬请期待！');
        }
        if (this.tabIndex === 1 && this.userIdentity < 3) {
          this.tabIndex = this.active;
          return this.$dialog.confirm({
            title: '提示',
            message: '您暂未开启专属店铺,如需采购请先开店噢!',
            confirmButtonText: '注册店主'
          }).then(() => {
            this.$router.push("/apply/pink");
          }).catch(() => {
          });
        }
        window.location.href = (type === 1 ? this.userHost : (this.baseHost + '/7999')) + url;
      } else if (type === 2) {
        this.$router.push(url);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.van-tabbar {
  z-index: 666;

  .van-tabbar-item {
    text-align: center;

    .van-tabbar-item__text {
      span {
        font-size: 20px;
      }

      p {
        color: #929292;
        margin-top: 5px;
      }
    }
  }

  .van-tabbar-item--active {
    color: #ff98a0;

    .van-tabbar-item__text {
      p {
        color: #ff98a0;
      }
    }
  }
}
</style>