<template>
  <div class="container">
    <div class="cus-header">
      <van-search shape="round" placeholder="请输入搜索关键词" @click="goSearch" />
      <div class="chain-total">连锁店铺总数<span>{{ chainTotal }}</span></div>
      <div class="brand-list" v-if="shopInfoByBid">
        <div class="b-item" @click="changeBrand(item, index)" :key="index" v-for="(item, index) in userShopData"
          :class="{ 'cus-active': item.brandId === shopInfoByBid.brandId }">
          <p>{{ item.brandId === 4 ? '大健康连锁店铺' : '美妆连锁店铺' }}</p>
          <span>{{ item.chainStoreNum }}</span>
        </div>
      </div>
    </div>
    <div class="shop-info" v-if="shopDataByAgentId">
      <div class="category">
        <template v-for="(item, index) in twoShopSon">
          <div class="shop-item" :key="index" v-if="shopDataByAgentId.agentLevel > 3 || item.num > 0">
            <div>
              <span>{{ item.name }}</span>
              <template v-if="index === 0 && shopDataByAgentId.agentLevel === 5">
                <img class="crown" src="../../assets/images/gold.jpg" v-if="isGoldCrown === 1"
                  @click="changeCrown(0)" />
                <img class="crown" src="../../assets/images/silver.png" @click="changeCrown(1)" v-else />
              </template>
              <div>{{ item.num }}</div>
              <van-icon name="arrow" v-if="item.num > 0 && (index < 1 || shopDataByAgentId.agentLevel === 3)"
                @click="handleFlagship(0)" />
            </div>
            <!-- 超V -->
            <div class="super-v" v-if="shopDataByAgentId.agentLevel === 4 && item.cvNum > 0">
              可查看超V：{{ item.cvNum }}
              <van-icon name="arrow" color="#FF98A0" @click="handleFlagship(1)" />
            </div>
          </div>
        </template>
        <template v-for="item in twoShopSon">
          <div class="shop-item" :key="item.name" v-if="!(shopDataByAgentId.agentLevel > 3 || item.num > 0)">
            <span class="empty"></span>
          </div>
        </template>
      </div>
      <div class="shop-detail ">
        <div class="time">
          <span>{{ getTime }}</span>数据
          <div class="time-change" @click="showTimeLayer = true">切换
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="category">
          <div class="shop-item">
            <span>当月新增连锁店铺</span>
            <div>{{ shopDataByAgentId.newPerson }}</div>
            <van-icon name="arrow" v-if="shopDataByAgentId.newPerson > 0" @click="handleShopChange(1)" />
          </div>
          <div class="shop-item">
            <span>当月失效连锁店铺</span>
            <div>{{ shopDataByAgentId.lessenPerson }}</div>
            <van-icon name="arrow" v-if="shopDataByAgentId.lessenPerson > 0" @click="handleShopChange(2)" />
          </div>
        </div>
      </div>
      <div class="agent-list">
        <div class="list-title">美妆连锁店铺排行榜TOP5</div>
        <ManageUserList :finished="true" :user-list="shopDataByAgentId.agentlist"
          :agent-level="shopDataByAgentId.agentLevel" @change="viewOtherAgent" />
      </div>

    </div>

    <van-popup v-model="showTimeLayer" position="bottom">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate"
        @confirm="onTimeConfirm" @cancel="showTimeLayer = false" />
    </van-popup>
  </div>
</template>

<script>
import ManageUserList from "../../components/ManageUserList";
import { formatTimeArr } from "../../utils/formatTime"

export default {
  name: "index",
  data() {
    return {
      chainTotal: 0,
      agentId: this.$route.params.id,
      timeArr: formatTimeArr(new Date()),
      isGoldCrown: 0,
      currentDate: new Date(),
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(),
      showTimeLayer: false,
      twoShopSon: [
        { name: "旗舰店铺", num: 0, cvNum: 0, level: 3 },
        // { name: "超V", num: 0, cvNum: 0, level: 2 }
      ],//旗舰店专卖店
      shopInfoByBid: null,//根据品牌获取店铺信息
      userShopData: null,//用户店铺信息
      shopDataByAgentId: null, //根据agentId获取店铺信息
      miShopDataByAgentId: null //某某代理蜜店店铺信息
    }
  },
  components: {
    ManageUserList
  },
  created() {
    this.getShopInfo();
  },
  methods: {
    onSearch() {

    },
    //切换品牌
    changeBrand: function (item, index) {
      this.shopInfoByBid = this.userShopData[index];
      this.isGoldCrown = this.shopInfoByBid.agentLevel === 5 ? 1 : 0;
      this.shopDataByAgentId = null;
      this.getOtherInfo();
    },
    //切换皇冠
    changeCrown(val) {
      this.isGoldCrown = val;
      this.shopDataByAgentId = null;
      this.getOtherInfo();
    },
    //选择时间
    onTimeConfirm(value) {
      this.timeArr = formatTimeArr(value);
      this.shopDataByAgentId = null;
      this.showTimeLayer = false;
      this.getOtherInfo();
    },
    //修改agentId
    onAgentChange(id) {
      this.agentId = id;
      this.shopInfoByBid = null;
      this.isGoldCrown = 0;
      this.chainTotal = 0;
      this.shopDataByAgentId = null;
      this.getShopInfo();
    },
    //查看其它代理
    viewOtherAgent(item) {
      this.$router.push({ name: "ShopManage", params: { "id": item.agentId } });
    },
    //根据agentId获取店铺信息
    getShopInfo() {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.post("/TeamAgent/ChainStore/GetTeamStore", {
        agentId: this.agentId
      }).then((json) => {
        this.$toast.clear();
        if (json.code === 1) {
          let list = json.response || [];
          if (list.length > 0) {
            let totalNum = 0;
            for (let i = 0; i < list.length; i++) {
              totalNum += list[i].chainStoreNum;
            }
            this.chainTotal = totalNum;
            this.shopInfoByBid = list[0];
            this.userShopData = list;
            if (list[0].agentLevel === 5) {
              this.isGoldCrown = 1;
            }
            this.getOtherInfo()//获取其他店铺信息
          }
        }
      });
    },
    //获取连锁店排行榜和其他店铺数据
    getOtherInfo: function () {
      let arr = this.timeArr;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.post("/TeamAgent/ChainStore/GetTeamStoreInfo", {
        agentId: this.shopInfoByBid.agentId,
        dateTimes: arr[0] + "-" + arr[1],
        isGold: this.isGoldCrown,
      }).then(json => {
        if (json.code === 1) {
          this.$toast.clear()
          let data = json.response;
          data.agentlist = data.agentlist || [];
          this.shopDataByAgentId = data;
          let twoShopSon = this.twoShopSon;
          twoShopSon[0].num = this.isGoldCrown ? data.blueZuan : data.pinkZuan;
          twoShopSon[0].level = data.agentLevel;
          twoShopSon[1].num = data.greenZuan;
          twoShopSon[1].cvNum = data.cvCount;
        }
      })
    },
    //点击旗舰店
    handleFlagship(type) {
      let item = this.shopDataByAgentId;
      let level = item.agentLevel;
      this.$router.push({
        name: "FlagshipList",
        query: { id: this.shopInfoByBid.agentId, type, level, gold: this.isGoldCrown }
      });
    },
    //店铺变化情况 1、新增 2、流失
    handleShopChange(type) {
      let arr = this.timeArr;
      let item = this.shopDataByAgentId;
      let { agentId, brandId } = this.shopInfoByBid;
      this.$router.push({
        name: "ShopChangeList",
        params: { id: agentId, type: type, time: (arr[0] + "-" + arr[1]) },
        query: { brandId: brandId }
      });
    },
    //搜索
    goSearch() {
      this.$router.push({ name: "Search" });
    }
  },
  computed: {
    getTime() {
      let arr = this.timeArr;
      return arr[0] + "年" + arr[1] + "月"
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.onAgentChange(to.params.id);
    }
    next();
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/manage_index.scss";
</style>
