<template>
  <div v-if="getActList.length > 0 && userIdentity && userIdentity.length > 0">
    <div class="sales-act" v-for="act in getActList" :key="act.type">
      <h3>{{ act.txt }}</h3>
      <template v-for="item in act.list">
        <div class="double-act" v-if="item.subItem" :key="item.moduleKey">
          <act-item :item="item" :user-identity="userIdentity" />
          <act-item :item="item.subItem" :user-identity="userIdentity" />
        </div>
        <act-item v-else :userIdentityV4="userIdentityV4" :item="item" :user-identity="userIdentity" :key="item.moduleKey" @open="openRuleLayer" />
      </template>
    </div>
  </div>
</template>
<script>
import ActItem from './ActItem';
//linkType 1:跳user 2、跳本路径 3、跳h5
//type 1：促销活动 2：官方活动
export default {
  name: "Activity",
  props: {
    userIdentity: {
      type: Object,
      default() {
        return {}
      }
    },
    userIdentityV4: {
      type: Number
    }
  },
  components: { ActItem },
  data() {
    return {
      activityList: [
        //520充值
        {
          type: 2, moduleKey: "gzhMZmzcz202103", showShare: true, showRule: true, ruleIndex: 2,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309173641341.png',
          linkPath: '/Recharge/Love', brandTypeId: 3, bid: 1, linkType: 2,
          isShow: false, shareLinkType: 2, sharePath: '/Recharge/fjs_poster', minLevel: 1, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210304164657253.png',
          shareBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210304164635753.png',
          ruleBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210304164648441.png',
          customClass: 'year_mw', ignoreLevel: false
        },
        //蜜运会PK赛
        {
          type: 2, moduleKey: "gzhMZgamePK202109", showShare: false, showRule: false, ruleIndex: 1,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/2222/0d8868fa8a20439987b2e41b57119d822.png',
          linkPath: '/PKIndex', brandTypeId: 3, bid: 1, linkType: 2,
          isShow: false, shareLinkType: 2, sharePath: '/purchasePoster', minLevel: 2, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210916115257040.png',
          shareBtnPath: '',
          ruleBtnPath: '',
          customClass: 'pk-match', ignoreLevel: false, anyWayGo: false,//点击全部都跳转
        },
        //粉钻6折升级活动
        {
          type: 2, moduleKey: "gzhMZupPinkDiamond202109", showShare: true, showRule: false, ruleIndex: 1,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908135649791.png',
          linkPath: '/Redesign/html/applyStore/pinkDiamond.html?brandId=3', brandTypeId: 3, bid: 1, linkType: 3,
          isShow: false, shareLinkType: 2, sharePath: '/halfPoster', minLevel: 1, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908102931556.png',
          shareBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908102856378.png',
          ruleBtnPath: '', relativeKey: 'gzhCGQmes202109',
          customClass: 'up-and-fee', ignoreLevel: true, anyWayGo: true,//点击全部都跳转
        },
        //采购券满额送
        {
          type: 2, moduleKey: "gzhCGQmes202109", showShare: true, showRule: false, ruleIndex: 1,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908103251290.png',
          linkPath: '/rolorweb/html/recharge/buy_coupon.html', brandTypeId: 3, bid: 1, linkType: 1,
          isShow: false, shareLinkType: 2, sharePath: '/purchasePoster', minLevel: 2, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908102931556.png',
          shareBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908102856378.png',
          ruleBtnPath: '',
          customClass: 'up-and-fee', ignoreLevel: false, anyWayGo: true,//点击全部都跳转
        },
        //店主双十二蔓薇活动
        {
          type: 1, moduleKey: "gzhDJKcx20211212", showShare: true, showRule: true, ruleIndex: 4, isNewRule: true,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207161627084.gif',
          linkPath: '/Arongleweb/html/groupon/groupon_list.html?brandId=4', brandTypeId: 4, bid: 4, linkType: 3,
          isShow: false, shareLinkType: 2, sharePath: '/double12/mwPoster', minLevel: 2, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207161651985.png',
          shareBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207172752453.png',
          ruleBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207172807015.png',
          customClass: 'double1Mw12', ignoreLevel: false, customData: null, anyWayGo: false,//点击全部都跳转
        },
        //店主双十二蔓薇活动抽奖
        {
          type: 2, moduleKey: "gzhDJKcj202112", showShare: false, showRule: false, ruleIndex: 0, isNewRule: true,
          bannerPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211213172601957.png',
          linkPath: '/lottery/slot', brandTypeId: 4, bid: 4, linkType: 2,
          isShow: false, shareLinkType: 2, sharePath: '', minLevel: 2, maxLevel: 10,
          goBtnPath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207161651985.png',
          shareBtnPath: '',
          ruleBtnPath: '',
          customClass: 'double111', ignoreLevel: false, customData: null, anyWayGo: false,//点击全部都跳转
        },
      ],
      couponList: []
    }
  },
  created() {
    this.getActiveTime();
    this.getCouponAmount();
    this.getMWActTime();
  },
  methods: {
    getActiveTime: function () {
      let arr = [];
      let list = this.activityList;
      list.forEach(item => {
        arr.push(item.moduleKey);
      })
      this.post("/Base/Tools/ModuleIsShowListV2", {
        moduleKEY: arr.join(',')
      }).then(json => {
        if (json.code === 1) {
          let data = json.response || [];
          data.forEach(item => {
            if (item.IsShow) {
              for (let i = 0; i < list.length; i++) {
                let modelKey = list[i].moduleKey;
                if (item.ModuleKEY === modelKey) {
                  list[i].isShow = item.IsShow;
                  //显示双排的特殊处理
                  if (modelKey === "gzhMZupPinkDiamond202109") {
                    list.find(it => {
                      if (it.moduleKey === 'gzhCGQmes202109') {
                        it.bannerPath = "http://freesaasofficedata.oss.aliyuncs.com/miShop/20210908135731876.png";
                        return true;
                      }
                      return false
                    })
                  }
                  break;
                }
              }
            }
          });
          this.$emit('update', data);
        }
      });
    },
    //获取促销额度
    getCouponAmount() {
      this.get("/OrderGoods/ActivityOther/GetCouponAmount", "", 2).then(json => {
        if (json.code === 1) {
          let list = json.response;
          list.forEach(it => {
            if (it.brandId === 13) {
              it.label = "美妆";
              it.imagePath = "http://freesaasofficedata.oss.aliyuncs.com/miShop/20210603142803082.png";
            } else {
              it.label = "大健康";
              it.imagePath = "http://freesaasofficedata.oss.aliyuncs.com/miShop/20210603142856488.png";
            }
          })
          this.couponList = list;
        }
      })
    },
    //获取双12蔓薇活动时间
    getMWActTime() {
      this.get("/OrderGoods/Activity/GetHoursAndNums", "", 2).then(json => {
        if (json.code === 1) {
          let { luckNums, endTimes } = json.response;
          let nowTime = +new Date();
          endTimes = endTimes * 1000;
          let list = this.activityList.reverse();
          let item = list.find(it => {
            return it.moduleKey === 'gzhDJKcx20211212'
          });
          if (item) {
            item.customData = { luckNums, time: endTimes - nowTime }
            if (nowTime > endTimes) {
              item.bannerPath = 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211207162701156.gif';
            }
          }
        }
      })
    },
    //打开规则页面
    openRuleLayer(item) {
      this.$emit('open', item);
    }

  },
  computed: {
    //筛选促销活动
    getSaleActList() {
      let list = [];
      this.activityList.forEach(item => {
        if (item.type === 1 && item.isShow) {
          list.push(item);
        }
      });
      return list;
    },
    //筛选官方活动
    getOfficialActList() {
      let list = [];
      this.activityList.forEach(item => {
        if (item.type === 2 && item.isShow) {
          list.push(item);
        }
      });
      return list;
    },
    //获取活动列表
    getActList() {
      let list1 = [], list2 = [];
      this.activityList.forEach(item => {
        if (item.isShow) {
          if (item.type === 1) {
            list1.push(item);
          } else {
            list2.push(item);
          }
        }
      });
      let list = [];
      if (list2.length > 0) {
        list.push({ txt: '官方活动', list: list2 })
      }
      if (list1.length > 0) {
        list.push({ txt: '促销活动', list: list1 })
      }
      //检查是否有依赖。
      list.forEach(item => {
        let childList = item.list
        for (let i = 0; i < childList.length; i++) {
          if (childList[i].relativeKey) {
            let idx = childList.findIndex(it => {
              return it.moduleKey === childList[i].relativeKey;
            });
            if (idx >= 0) {
              childList[i].subItem = childList[idx];
              childList.splice(idx, 1);
            }
            break;
          }
        }
      });
      console.log(this.activityList, list);
      return list;
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-act {
  h3 {
    font-size: 16px;
    color: #666;
    padding: 10px 0;
  }
  /deep/ .sales-banner {
    .go-to {
      -webkit-animation: scaleDraw 1.5s ease-in-out infinite;
    }
  }
  .double-act {
    position: relative;
    padding-top: 10px;
    /deep/ .sales-banner {
      width: 54%;
      margin-bottom: 0;
      .share {
        top: -8px;
        width: 33px;
      }
      .go-to {
        top: initial;
        width: 36px;
        bottom: 8px;
      }
      &:nth-child(1) {
        .share {
          left: 10px;
        }
        .go-to {
          left: 8px;
        }
      }
      &:nth-child(2) {
        position: absolute;
        top: 10px;
        right: 0;
        .share {
          left: initial;
          right: 10px;
        }
        .go-to {
          right: 3px;
        }
      }
    }
  }
}
.ivv-anti-content {
  display: flex;

  .quota-content {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes scaleDraw {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
</style>