import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/front/iconfont.scss'
import "./assets/style/public.scss";

//配置vantUI
import Vant from 'vant'
import { Lazyload } from 'vant'
import 'vant/lib/index.css'

let rectLoading = "http://freesaasofficedata.oss.aliyuncs.com/miShop/20220406135248144.png";
let squareLoading = "http://freesaasofficedata.oss.aliyuncs.com/miShop/20220330101950317.png";
Vue.use(Vant)
Vue.use(Lazyload, {
    loading: rectLoading,
    filter: {
        progressive(listener) {
            let { type } = listener.el.dataset;
            if (type === '1') listener.loading = squareLoading;
        }
    }
});
// //测试
// Vue.prototype.baseUrl = "http://tapi2.rolormd.com";
// Vue.prototype.baseHost = "http://th5.rolormd.com"
// Vue.prototype.userHost = "http://tuser.rolormd.com"
// Vue.prototype.appHost = "http://test-app.rolormd.com"
// Vue.prototype.miniApp = "http://v2.rolormd.com" //微信小程序接口

//正式
Vue.prototype.baseUrl = "http://api2.rolormd.com";
Vue.prototype.baseHost = "http://h5.rolormd.com"
Vue.prototype.userHost = "https://user.rolormd.com"
Vue.prototype.appHost = "http://app3.rolormd.com"
Vue.prototype.miniApp = "http://miniv2.rolormd.com" //微信小程序接口

// Vue.prototype.appHost = "http://app.rolormd.com"

//配置请求
import { post, get, setBaseUrl } from './utils/axios'

setBaseUrl(Vue.prototype.baseUrl, Vue.prototype.appHost,Vue.prototype.miniApp)

Vue.prototype.post = post
Vue.prototype.get = get

//复制到粘贴板插件
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
