<template>
  <div :style="styleVar">
    <div class="list-detail" v-for="(it,index) in dataList" :key="index">
      <div class="img-con">
        <img :src="it.imageUrl">
        <div class="the-tag" v-if="it.tagImage">
          <img :src="it.tagImage" />
        </div>
        <div class="the-price" v-else-if="it.txt">
          <div class="scale-font">{{it.txt}}</div>
          <div class="second-line"><span class="scale-font">￥</span>{{it.worth}}
          </div>
        </div>

      </div>
      <div class="mui-media-body">
        <div class="gift-name" v-html="it.title"></div>
        <div class="gift-item" v-for="(itt,idd) in it.sublist" :key="idd">
          <span>{{idx + 1}}</span> {{itt}}
        </div>
        <div class="gift-detail" v-html="it.desc"></div>
      </div>
    </div>
  </div>
</template>
<script>
// 常用规则
export default {
  name: 'DailyItem',
  props: {
    dataList: {
      type: Array,
      value: []
    },
    mainColor: {
      type: String,
      value: ''
    }
  },
  data() {
    return {
      styleVar: {
        "--color": this.mainColor
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list-detail {
  padding: 20px 0 10px;
  border-bottom: solid 1px #f1f1f1;
  display: flex;

  .mui-media-body {
    text-align: left;
    overflow: hidden;
    width: calc(100% - 85px);

    .gift-name {
      color: #333;
      font-weight: bold;

      span {
        color: var(--color);
      }

      & + .gift-item {
        margin-top: 10px;
      }
    }

    .gift-item {
      color: #999;
      font-size: 12px;
      padding: 3px 0;

      span {
        width: 14px;
        height: 14px;
        color: #d2201f;
        text-align: center;
        line-height: 14px;
        margin-right: 5px;
        border: 1px solid #d2201f;
        display: inline-block;
        border-radius: 50%;
      }
    }

    /deep/ .gift-detail {
      margin: 5px 0 0;
      font-size: 11px;
      color: #999999;

      span {
        color: var(--color);
      }
      div {
        padding-bottom: 3px;
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .img-con {
    position: relative;
    width: 60px;
    height: 60px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
    }

    .the-price {
      position: absolute;
      right: -12px;
      top: -18px;
      width: 44px;
      height: 44px;
      background-image: url("../../assets/images/home/activity/price-bg.png");
      background-size: 100% 100%;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .scale-font {
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        -ms-transform: scale(0.75);
        -o-transform: scale(0.75);
        transform: scale(0.75);
      }

      .second-line {
        margin-top: -3px;

        .scale-font {
          margin: 0 -3px 0 -2px;
        }
      }
    }
    .the-tag {
      position: absolute;
      top: -5px;
      left: 0;
      width: 32px;
    }
  }

  &.prompt {
    padding-top: 10px !important;
    justify-content: center;

    p {
      text-align: center;
    }
  }
}
</style>