<template>
    <div class="StartStore">
        <div v-show="index === 9" class="StartStore_box">
            <button class="StartCloseBtn" @click="StartCloseBtn">x</button>
            <h4 class="haoli_bg_img">您暂未开启店铺</h4>
            <div class="StartStore_Introduction">
                <p>
                    <button>订单豪礼</button>
                    为店铺专享，三重豪礼100%中奖奢宠
                </p>
                <p>助力您的店铺采购! 您可开店-开旗舰店</p>
                <p>臻启您的店铺系统!</p>
                <button @click="StartShopBtn">
                    开店-点击前往开旗舰店
                </button>
            </div>
        </div>
        <div v-show="index === 8" class="StartStore_box">
            <button class="StartCloseBtn" @click="StartCloseBtn">x</button>
            <h4>您暂未开启店铺</h4>
            <div class="StartStore_Introduction">
                <p>
                    <button>物料专区</button>
                    为店铺专享，辅助您开店后的店铺
                </p>
                <p>零售噢! 您可开店-开旗舰店臻启您的</p>
                <p>店铺系统查看!</p>
                <button @click="StartShopBtn">
                    开店-点击前往开旗舰店
                </button>
            </div>
        </div>
        <div v-show="index === 12" class="StartStore_box">
            <button class="StartCloseBtn" @click="StartCloseBtn">x</button>
            <h4>您暂未开启店铺</h4>
            <div class="StartStore_Introduction">

                <p>
                    <button>买采购券</button>
                    为店铺专享噢! 您可
                </p>
                <p>开店-开旗舰店臻启您的店铺系统!</p>
                <button @click="StartShopBtn">
                    开店-点击前往开旗舰店
                </button>
            </div>
        </div>
        <div v-show="index === 10" class="StartStore_box">
            <button class="StartCloseBtn" @click="StartCloseBtn">x</button>
            <h4>您暂未开启店铺</h4>
            <div class="StartStore_Introduction">
                <p>
                    <button>零售管理</button>
                    为店铺专享噢! 您可
                </p>
                <p>开店-开旗舰店臻启您的店铺系统!</p>

                <button @click="StartShopBtn">
                    开店-点击前往开旗舰店
                </button>
            </div>
        </div>
        <div v-show="index === 11" class="StartStore_box">
            <button class="StartCloseBtn" @click="StartCloseBtn">x</button>
            <h4>您暂未开启店铺</h4>
            <div class="StartStore_Introduction">
                <p>
                    <button>采购订货</button>
                    为店铺专享噢! 您可
                </p>
                <p>开启您的专属店铺!</p>

                <button @click="StartShopBtn">
                    采购开专卖店
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "ShopDialog",
    props: {
      index: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {}
    },
    created() {
      console.log(this.index);
    },
    methods: {
      StartCloseBtn() {
        this.$emit('close');
      },
      StartShopBtn() {
        // window.location.href = this.userHost + "/rolorweb/html/recharge/register.html"
        this.$router.push("/apply/pink")
      }
    }
  }
</script>

<style lang="scss" scoped>
    .StartStore {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;

        .StartStore_box {
            .haoli_bg_img {
                background-image: url("../../assets/images/home/StartStore_img.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            position: relative;
            border-radius: 14px;
            width: 86%;
            text-align: center;
            height: 240px;

            &:nth-child(3) {
                height: 208px;
            }

            &:nth-child(4) {
                height: 208px;
            }

            .StartCloseBtn {
                position: absolute;
                width: 24px;
                height: 24px;
                background-color: #fff;
                color: #ff98a0;
                border-radius: 50%;
                top: 10px;
                font-size: 16px;
                right: 6%;
                border: none;
                padding: 0;
                line-height: 20px;
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4),&:nth-child(5) {
                background-image: url("../../assets/images/home/wuliao.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            background-color: #fcc7cc;

            > h4 {
                text-indent: 20px;
                margin: 0;
                padding: 16px 0;
                color: #fff;
                font-size: 22px;
                letter-spacing: 2px;
            }

            > div {
                margin: 0 6px;
                background-color: #fff;
                border-radius: 6px;
                padding: 20px 0;

                p {
                    color: #666;
                    font-size: 14px;
                    margin-bottom: 10px;
                    letter-spacing: 0.5px;

                    > span {
                        color: #ff98a0;

                    }

                    button {
                        background: transparent;
                        border: none;
                        padding: 0;
                        color: #ff98a0;
                        letter-spacing: 0.5px;
                    }
                }

                > button {
                    width: 66%;
                    border-radius: 30px;
                    background-color: #ff98a0;
                    border: none;
                    color: #fff;
                    padding: 8px 12px;
                }
            }
        }
    }
</style>