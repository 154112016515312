<template>
  <div class="text-rule-list" :style="styleVar">
    <div class="rule-item" :class="{'padleft':dataItem.serialType > 0}" v-for="(item,index) in dataItem.list" :key="index">
      <span class="num round" v-if="dataItem.serialType === 1">{{index + 1}}</span>
      <span class="num" v-else-if="dataItem.serialType === 2">{{index + 1}})</span>
      <div class="rule-text" :style="'color:' + item.textColor" v-html="item.title"></div>
      <div class="sub-rule-list" v-if="item.childList && item.childList.length > 0">
        <div class="sub-rule-item" v-for="(it,idx) in item.childList" :key="idx">
          <div class="rule-text" v-html="it.text"></div>
          <text-item :data-item="it"  v-if="it.list"/>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
// 文字规则
import TextItem from "./TextItem"
export default {
  name: 'TextItem',
  components: { TextItem },
  props: {
    dataItem: {
      type: Object,
      value() {
        return {}
      }
    }
  },
  data() {
    return {
      styleVar: {
        "--color": this.dataItem.thirdColor,
      }
    }
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
.text-rule-list {
  color: #666666;
  padding-top: 10px;
  text-align: left;
  .rule-item {
    position: relative;
    padding-bottom: 3px;
    .num {
      position: absolute;
      top: 0;
      left: 0;
    }
    .round {
      color: #fff;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--color);
    }
    .rule-text {
      /deep/ span {
        color: var(--color);
      }
    }
    &.padleft {
      padding-left: 20px;
    }
  }
  .sub-rule-list {
    .sub-rule-item {
      padding-top: 10px;
      .rule-text {
      }
    }
  }
}
</style>