<template>
  <div class="brand-item">
    <div class="title">{{title}}<span>{{describe}}</span></div>
    <div class="more" @click="jumpList()">发现更多<span class="icon-arrow-right"></span></div>
    <van-swipe class="swipe-banner" :autoplay="5000" indicator-color="white">
      <van-swipe-item v-for="(item,index) in imgList" :key="index">
        <a :href="item.link">
          <img src="../../assets/images/home/home-00.png" v-lazy="item.imgUrl" />
        </a>
      </van-swipe-item>
    </van-swipe>
    <div class="pro-list">
      <div class="pro-item" v-for="(item,index) in list" :key="index" @click="pageJump(item)">
        <img class="media-object" :src="item.goodsImg">
        <div class="media-body">
          <div class="pro-name">
            {{ item.goodsName }}
          </div>
          <!-- <div class="prod-txt ellipsis1">{{ item.goodsContent }}</div> -->
          <div class="ivv-price"><span>￥</span>{{ item.goodsPrice }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductByBrandId",
  props: {
    title: {
      type: String,
      default: ''
    },
    describe: {
      type: String,
      default: ''
    },

    brandId: {
      type: Number,
      default: 3
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    imgList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    pageJump(item) {
      window.location.href = this.baseHost + '/7999/MiDian/html/seller/groupon_detail.html?proId=' + item.goodsId + '&brandId=' + this.brandId
    },
    //跳转到列表
    jumpList() {
      this.$router.push("/brand/list?brandId=" + this.brandId);
    }
  }
}
</script>

<style lang="scss" scoped>
$imageWidth: 28vw;

.brand-item {
  position: relative;

  .title {
    color: #666;
    font-size: 16px;
    padding: 13px 0;

    span {
      color: #999;
      font-size: 12px;
      padding-left: 6px;
    }
  }

  .more {
    position: absolute;
    top: 15px;
    right: 0;
    width: 74px;
    height: 18px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    background-color: #ff99a1;

    .icon-arrow-right {
      font-size: 10px;
    }
  }

  .pro-list {
    overflow: hidden;
    display: flex;
    overflow-x: auto;

    .pro-item {
      width: calc(50% - 5px);
      font-size: 0;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 6px;
      margin-right: 10px;
      background-color: #fff;

      .media-object {
        width: 100%;
      }

      .media-body {
        padding: 8px 10px 8px;

        .pro-name {
          color: #333;
          font-size: 12px;
        }

        .prod-txt {
          color: #999;
          font-size: 10px;
        }

        .ivv-price {
          color: #ff99a1;
          font-size: 12px;

          span {
            font-size: 10px;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:after {
      content: '';
      clear: both;
    }
  }
}
.swipe-banner {
  margin-bottom: 10px;
  .van-swipe-item {
    img {
      width: 100%;
    }
  }
}
</style>