const setCookie = (name, value) => {
  let exdate = new Date();
  exdate.setDate(exdate.getDate() + 1);
  let domain = process.env.NODE_ENV === 'production' ? ';path=/;domain=rolormd.com' : '';
  document.cookie = name + "=" + value + ";expires=" + exdate.toGMTString() + domain;
}
const getCookie = (key) => {
  let cookies = document.cookie;
  let result = cookies.match(new RegExp(key + "=([^\;]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return result[1];
}
export {
  setCookie,
  getCookie
}