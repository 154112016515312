const formatTime = (date, type, symbol = '/') => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  if (type === 'YY-MM-DD') {
    return [year, month, day].map(formatNumber).join(symbol)
  }
  return [year, month, day].map(formatNumber).join(symbol) + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
const formatTimeArr = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return [year, month, day, hour, minute, second].map(formatNumber)
}
const dateFormat = (time, format) => {
  const t = new Date(time)
  // 日期格式
  format = format || 'Y-M-D H:I:S'
  let year = t.getFullYear()
  // 由于 getMonth 返回值会比正常月份小 1
  let month = t.getMonth() + 1
  let day = t.getDate()
  let hours = t.getHours()
  let minutes = t.getMinutes()
  let seconds = t.getSeconds()

  const hash = {
    'y': year,
    'm': month,
    'd': day,
    'h': hours,
    'i': minutes,
    's': seconds
  }
  // 是否补 0
  const isAddZero = (o) => {
    return /M|D|H|I|S/.test(o)
  }
  return format.replace(/\w/g, o => {
    let rt = hash[o.toLocaleLowerCase()];
    return rt >= 10 || !isAddZero(o) ? rt : `0${rt}`
  })
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
const formatTimeStr = n => {
  n = n.toString()
  return n.replace(/\-/g, "/")
}


export {
  formatTime,
  formatTimeArr,
  formatTimeStr,
  dateFormat
}
