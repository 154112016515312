<template>
  <div class="brand-list" v-if="homeData">
    <BrandProduct title="美妆商品" describe="自营品牌" :brand-id="3" :list="homeData.mzList" :img-list="homeData.mzimgList"/>
    <BrandProduct title="大健康商品" describe="澳洲直邮" :brand-id="4" :list="homeData.djkList"  :img-list="homeData.djkimgList"/>
  </div>
</template>

<script>
import BrandProduct from "./BrandProduct"

export default {
  name: "HomeRecommend",
  data() {
    return {
      homeData: null
    }
  },
  components: { BrandProduct },
  created() {
    this.getHomeProduct();
  },
  methods: {
    getHomeProduct() {
      this.get('/GroupBuy/Product/GetProductHotListV2', '', 2).then(json => {
        if (json.code === 1) {
          this.homeData = json.response;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-list {
  padding: 0 15px 60px;
  background-color: #f8f8f8;
  /*background-color: #ff4444;*/
}
@media screen and (max-width: 350px) {
  .brand-list {
    padding: 0 8px 60px;
  }
}
</style>