<template>
  <div>
    <van-cell-group>
      <van-field v-model="userId" label="账户" />
      <van-field v-model="password" label="文本" type="password" />
      <van-button type="danger" @click="onSure">确定</van-button>
    </van-cell-group>
  </div>
</template>

<script>
import { setCookie } from "../../utils/cookies";

export default {
  name: "Index",
  data() {
    return {
      userId: '',
      password: ''
    }
  },
  methods: {
    onSure() {
      if (process.env.NODE_ENV !== "development") {
        if (this.password !== '123456789') return
      }
      if (this.userId) {
        this.post("/Base/Debug/GetToken?", { "userid": this.userId, "&token": "Login" }).then((json) => {
          if (json.code === 1) {
            if (json.response) {
              setCookie("token", json.response)
              setCookie("E", json.msg);
              localStorage.setItem("token", json.response)
              localStorage.setItem("E", json.msg)
              this.$router.push("/")
            }
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
