<template>
    <div class="container" v-if="agentInfo">
        <div class="cus-header">
            <img :src="agentInfo.headImgUrl" v-if="agentInfo.headImgUrl" />
            <img src="../../assets/images/100.png" v-else />
            <div class="user-info">
                <div class="cus-row">
                    <span class="name">{{ agentInfo.agentName }}</span>
                    <span class="bind">{{ agentInfo.stateStr }}</span>
                </div>
                <div class="cus-row">
                    <span class="shop-level">{{ agentInfo.levelName }}{{agentInfo.agentLevel > 2 ? '店' : ''}}</span>
                    <!-- |{{ agentInfo.agentCode }} -->
                </div>
            </div>
        </div>
        <div class="cus-content">
            <div class="phone-area">
                <van-icon name="phone-o" />
                <span class="txt">电话 ：</span>
                <span class="number">{{ agentInfo.phone }}</span>
                <a class="call-btn" :href="'tel:' + agentInfo.phone">拨打</a>
            </div>
            <div class="cus-panel">
                <van-row class="pan-header">
                    <van-col span="24" align="center">详细资料</van-col>
                </van-row>
                <van-row>
                    <van-col span="12">
                        <span>开店时间</span>
                        <div>{{ agentInfo.openTimes }}</div>
                    </van-col>
                    <van-col span="12">
                        <span>{{ agentInfo.agentLevel > 2 ? '店铺采购订单数' : '超V定制订单数' }}</span>
                        <div>{{ agentInfo.orderNum }}</div>
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="12">
                        <span>{{ agentInfo.agentLevel > 2 ? '店铺采购业绩' : '定制商品业绩' }}</span>
                        <div>{{ agentInfo.achievement }}元</div>
                    </van-col>
                    <van-col span="12" v-if="agentInfo.agentLevel > 2">
                        <span>连锁店铺总数</span>
                        <div>{{ agentInfo.chainStoreTotal }}店</div>
                    </van-col>
                </van-row>
                <template v-if="agentInfo.agentLevel > 2">
                    <van-row>
                        <van-col span="12">
                            <span>当月新增连锁店铺</span>
                            <div>{{ agentInfo.newPerson }}店</div>
                        </van-col>
                        <van-col span="12">
                            <span>待审核连锁店铺</span>
                            <div>{{ agentInfo.examineStore }}店</div>
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col span="12">
                            <span>已失效连锁店铺</span>
                            <div>{{ agentInfo.lessenPerson }}店</div>
                        </van-col>
                    </van-row>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserInfo",
    data() {
        return {
            agentId: this.$route.params.id,
            agentInfo: null
        }
    },
    created() {
        this.getAgentInfo();
    },
    methods: {
        getAgentInfo() {
            this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
            this.post("/TeamAgent/ChainStore/GetTeamAgentInfo", {
                agentId: this.agentId
            }).then((json) => {
                this.$toast.clear();
                if (json.code === 1) {
                    this.agentInfo = json.response;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 24px 28px;
}

.cus-header {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 44px;
        height: 44px;
        margin-right: 10px;
        border-radius: 50%;
    }

    .user-info {
        text-align: left;

        .cus-row {
            .name {
                color: #666;
                font-size: 16px;
            }

            .bind {
                color: #fff;
                font-size: 10px;
                line-height: 10px;
                border-radius: 6px;
                padding: 1px 5px;
                margin-left: 5px;
                display: inline-block;
                vertical-align: text-top;
                background-color: #FF98A0;
            }

            .shop-level {
                color: #666;
                font-size: 12px;
            }
        }
    }
}

.cus-content {
    margin-top: 20px;
    border-radius: 8px;
    padding: 20px 15px 10px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

    .phone-area {
        color: #999;
        font-size: 14px;
        text-align: left;

        .van-icon {
            font-size: 20px;
            padding-right: 11px;
            display: inline-block;
            vertical-align: middle;
        }

        .number {
            color: #666;
        }

        .call-btn {
            float: right;
            color: #fff;
            font-size: 12px;
            padding: 3px 8px;
            line-height: 12px;
            border-radius: 10px;
            background-color: #FF98A0;
        }
    }

    .cus-panel {
        padding-top: 20px;

        .van-row {
            padding: 14px 0;
            border-top: 1px solid #F1F1F1;

            span {
                color: #999;
                font-size: 12px;
            }

            div {
                color: #666;
                font-size: 16px;
            }

            .van-col {
                position: relative;
                padding-left: 10px;

                >div {
                    margin-top: 6px;
                }

                &:first-child {
                    &:after {
                        content: '';
                        position: absolute;
                        top: -14px;
                        right: 0;
                        bottom: -14px;
                        width: 1px;
                        background-color: #F1F1F1;
                    }
                }
            }
        }

        .pan-header {
            padding: 0;
            line-height: 40px;
            background: linear-gradient(to bottom, #F6F6F6, #ffffff);

            div {
                font-size: 12px;
            }

            .van-col {
                &:first-child {
                    &:after {
                        width: 0;
                    }
                }
            }
        }
    }
}
</style>