const axios = require('axios');
import { setCookie } from "./cookies";
import Cookies from "js-cookie";

axios.defaults.baseURL = "";
let portList = [
    { name: '/api', host: '' },
    { name: '/app', host: '' },
    { name: '/v2', host: '' },
    { name: '/aliyun', host: 'http://freesaasofficedata.oss.aliyuncs.com' }
]
axios.defaults.withCredentials = true;
const get = (url, data = {}, type = 1, guid) => {
    if (guid) {
        setCookie('deviceId', guid)
    }
    return new Promise((resolve, reject) => {
        axios.get(getBaseUrl(type) + url, {
            params: data,
        }).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}
const put = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(data => {
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const post = (url, data = {}, formdata = true, type = 1) => {
    if (formdata) {
        const formdata = new FormData()
        for (let k in data) {
            formdata.append(k, data[k])
        }
        data = formdata
    }
    return new Promise((resolve, reject) => {
        axios.post(getBaseUrl(type) + url, data)
            .then(data => {
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
axios.interceptors.request.use(
    config => {
        // const token = Cookies.get("E");
        // if (token) config.headers['Authorization'] = token;
        return config
    }
)
axios.interceptors.response.use(
    response => {
        let { params } = response.config; //无视登录
        let ignoreLogin = params ? params.IgnoreLogin : false;
        if (response.data.code === -1 && !ignoreLogin) {
            //登录过期 重定向到登录界面
            // return console.log(response)
            let url = window.location.href;
            window.location.href = getBaseUrl(1) + '/Base/FairWechatLogin/LoginInfo?url=' + encodeURIComponent(url);
        }
        if (response.data.code === -999) {
            //登录过期 重定向到登录界面
            // return console.log(response)
            window.location.href = '/' + '#/fail404'
        }
        return response.data
    }
)

const setBaseUrl = (_baseUrl, _appHost, _miniHost) => {
    portList[0].host = _baseUrl
    portList[1].host = _appHost
    portList[2].host = _miniHost
}
const getBaseUrl = (type = 1) => {
    if (process.env.NODE_ENV === 'development') {
        return portList[type - 1].name
    } else {
        return portList[type - 1].host
    }
}
export {
    post,
    get,
    setBaseUrl
}
