<template>
    <div>
        <div class="user-list" v-if="userList.length > 0">
            <div class="u-item" v-for="(item, index) in userList" :key="index">
                <img class="media-object" :src="item.headImgUrl" v-if="item.headImgUrl" />
                <img class="media-object" src="../assets/images/100.png" v-else />
                <template v-if="agentLevel === 5">
                    <img class="crown" src="../assets/images/gold.jpg" v-if="item.agentLevel === 4" />
                    <img class="crown" src="../assets/images/silver.png" v-else />
                </template>
                <div class="media-body">
                    <div class="user">
                        <div class="name ellipsis1">{{ item.agentName }}</div>
                        <!-- <span class="level-code">{{ item.agentCode }}</span> -->
                        <span class="new-user" v-if="item.isMonthNew">当月新增</span>
                    </div>
                    <div class="shop-info" v-if="item.agentLevel > 2">
                        <div class="s-item">连锁店铺总数 : <span>{{ item.chainStoreTotal }}</span></div>
                        <div class="s-item">当月新增连锁店铺 : <span>{{ item.chainStoreNew }}</span></div>
                        <div class="s-item">已失效连锁店铺 : <span>{{ item.chainStoreLessen }}</span></div>
                    </div>
                    <div class="operate">
                        <van-button plain type="primary" @click="onClick(item, 1)">信息</van-button>
                        <template v-if="item.agentLevel >= 3">
                             <van-button plain type="primary" @click="onClick(item, 4)">前往TA店铺</van-button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-else-if="finished">
            <img src="../assets/images/empty/content.png" />
            <p>暂无数据~</p>
        </div>
        <!--蜜店店铺小程序-->
        <div class="mi-shop-layer" v-if="miShopDataByAgentId">
            <div class="mi-shop-content">
                <img class="user-head" :src="miShopDataByAgentId.headImgUrl" v-if="miShopDataByAgentId.headImgUrl" />
                <img class="user-head" src="../assets/images/100.png" v-else />
                <van-icon name="close" @click="miShopDataByAgentId = null"></van-icon>
                <div class="agent-name">{{ miShopDataByAgentId.agentName }}</div>
                <div class="shop-level">{{ miShopDataByAgentId.agentLevel >= 3 ? '旗舰店' : '超V' }}
                    <!--  |{{ miShopDataByAgentId.agentCode }} -->
                </div>
                <div class="agent-phone">电话：{{ miShopDataByAgentId.agentPho }}</div>
                <div class="wx-app-code">
                    <img :src="miShopDataByAgentId.qrcodeUrl" />
                </div>
                <div class="prompt">长按选择保存图片</div>
            </div>
        </div>
        <!--采购分配-->
        <div class="modal-box" v-if="superiorInfo">
            <div class="modal-text">
                <!--采购店铺-->
                <p>采购店铺</p>
                <div class="purchase">
                    <div class="purchase-left">
                        <img :src="superiorInfo.headImgUrl" v-if="superiorInfo.headImgUrl">
                        <img src="../assets/images/100.png" v-else />
                    </div>
                    <div class="purchase-right">
                        <p>{{ superiorInfo.agentName }}</p>
                        <p><span>{{ superiorInfo.phone != 0 ? superiorInfo.phone : "无" }}</span>
                            <!-- <span>|</span> <span>{{ superiorInfo.agentCode }}</span> -->
                        </p>
                    </div>
                </div>
                <!--邀请人-->
                <p class="invite">邀请人</p>
                <div class="invite-people">
                    <div class="purchase-left">
                        <img :src="inviterMsg.headImgUrl" v-if="inviterMsg.headImgUrl">
                        <img src="../assets/images/100.png" v-else />
                    </div>
                    <div class="purchase-right">
                        <p>{{ inviterMsg.agentName }}</p>
                        <p><span>{{ inviterMsg.phone != 0 ? inviterMsg.phone : "无" }}</span> 
                            <!-- <span>|</span>
                            <span>{{ inviterMsg.agentCode }}</span> -->
                        </p>
                        <p v-show="isModifyInviter" class="hide-text">
                            <label>手机号:</label>
                            <input type="number" v-model="phone" />
                            <span @click="verification">验证信息</span>
                        </p>
                    </div>
                    <span @click="isModifyInviter = true" class="change-btn">修改</span>
                </div>
                <!-- <button @click="toPoster">生成邀请海报</button> -->
                <van-icon @click="superiorInfo = null" name="close" size="24px" />
            </div>
        </div>
        <!--        <div v-for="page in pages" :key="page.uuid" :style="'background-color:' + (currentPageUuid === page.id ? '#fff' :'#000')"></div>-->
    </div>
</template>

<script>
export default {
    name: "ManageUserList",
    props: {
        userList: {
            type: Array,
            default() {
                return [];
            }
        },
        agentLevel: {
            type: Number,
            default: 0
        },
        finished: false
    },
    data() {
        return {
            phone: "",
            inviterMsg: null,//邀请人信息
            superiorInfo: null, //上级信息
            isModifyInviter: false,//是否修改推荐人
            miShopDataByAgentId: null
        }
    },
    created() {
        //比如页面上要用userList这个字段，
        // 我在这里调用一个方法。然后把userList里面的值改掉
    },
    methods: {
        onClick(item, index) {
            switch (index) {
                case 1:
                    this.$router.push({ name: "UserInfo", params: { "id": item.agentId } });
                    break;
                case 2:
                    this.$emit('change', item);
                    break;
                case 3:
                    this.phone = "";
                    this.isModifyInviter = false;
                    this.getDistribution(item);
                    break;
                default:
                    this.getShopWxCodeById(item);
                    break;
            }
        },
        getShopWxCodeById: function (item) {
            this.$toast.loading({ message: '加载中...', overlay: true, duration: 0 });
            this.get("/TeamAgent/ChainStore/GetCard?agentId=" + item.agentId).then((json) => {
                this.$toast.clear();
                if (json.code === 1) {
                    let { agentPho, qrcodeUrl } = json.response;
                    item.agentPho = agentPho;
                    item.qrcodeUrl = qrcodeUrl;
                    this.miShopDataByAgentId = item;
                } else {
                    this.$toast(json.msg);
                }
            });
        },
        //获取分配信息
        getDistribution: function (item) {
            this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
            this.post("/TeamAgent/ChainStore/GetFenpeiInfo", {
                agentId: item.agentId
            }).then(json => {
                if (json.code === 1) {
                    this.$toast.clear()
                    let data = json.response
                    this.superiorInfo = data
                    this.inviterMsg = data
                }
            })
        },
        //分配验证信息
        verification: function () {
            if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))) {
                console.log("手机号码不正确")
                return false;
            }
            this.setDistributionMsg(this.phone, this.superiorInfo.agentId)
        },
        //设置分配信息
        setDistributionMsg: function (tell, id) {
            this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
            this.post("/TeamAgent/ChainStore/SetFenpeiInfo", {
                phone: tell,
                agentId: id,
            }).then(json => {
                this.$toast.clear()
                if (json.code === 1) {
                    this.inviterMsg = json.response
                    this.phone = ''
                    this.isModifyInviter = false;
                } else {
                    this.$toast(json.msg);
                }
            })
        },
        //去生成海报
        toPoster() {
            window.location.href = this.baseHost + "/7999/Arongleweb/html/manage/poster.html?agentId=" + this.superiorInfo.agentId + "&pushManId=" + this.inviterMsg.pushManId
        }
    }
}
</script>

<style lang="scss" scoped>
.user-list {
    text-align: left;
    font-size: 12px;
    padding: 11px;
    background-color: #fff;

    .u-item {
        padding-top: 11px;
        position: relative;

        .media-object {
            width: 33px;
            height: 33px;
            float: left;
            margin-right: 10px;
            border-radius: 100%;
        }

        .crown {
            position: absolute;
            top: -6px;
            left: 23px;
            width: 15px;
            transform: rotateZ(45deg);
        }

        .media-body {
            overflow: hidden;
            border-bottom: 1px solid #F1F1F1;

            .user {
                .name {
                    float: left;
                    max-width: calc(100% - 150px);
                }

                .level-code {
                    color: #999;
                    padding: 0 5px;
                    font-size: 10px;
                }

                .new-user {
                    color: #FF98A0;
                    font-size: 9px;
                    line-height: 9px;
                    padding: 1px 3px;
                    border-radius: 5px;
                    display: inline-block;
                    border: 1px solid #FF98A0;
                }
            }

            .shop-info {
                width: 100%;
                display: flex;
                flex-wrap: wrap;


                .s-item {
                    color: #999;
                    width: 50%;
                    margin-top: 5px;
                    font-size: 11px;

                    span {
                        color: #666;
                    }
                }

            }

            .operate {
                padding: 10px 0;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                box-sizing: border-box;

                .van-button {
                    color: #999;
                    font-size: 12px;
                    height: 20px;
                    padding: 2px 8px;
                    line-height: 15px;
                    border-radius: 15px;
                    border-color: #B5B5B5;
                    margin-left: 10px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        &:first-child {
            padding-top: 0;
        }
    }


}

.empty {
    padding: 20px 0;
    text-align: center;
    background-color: #fff;

    img {
        width: 60%;
    }

    p {
        color: #999;
        font-size: 14px;
    }
}

//模态框
.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .4);

    .modal-text {
        height: 300px;
        width: 80%;
        box-sizing: border-box;
        padding: 30px 20px 10px;

        >button {
            width: 80%;
            background-color: #FF98A0;
            color: #fff;
            border: none;
            height: 34px;
            font-size: 14px;
            line-height: 34px;
            border-radius: 20px;
            margin-left: 12.5%;
            margin-top: 16px;
        }

        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 10px;
        color: #666666;

        >.van-icon {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .top {
            text-align: center;
            font-size: 16px;

        }

        .purchase {
            margin-bottom: 14px;
            padding: 10px 0;
            display: flex;
            border-bottom: 1px solid #F1F1F1;

            img {
                border-radius: 50%;
            }
        }

        .purchase-left {
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }

        .purchase-right {
            padding-left: 14px;

            p:nth-child(2) {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #999999;
                font-size: 12px;
                margin-top: 10px;

                span:nth-child(1) {
                    width: 74px;
                }

                span:nth-child(2) {
                    width: 4px;
                    margin: 0 2px;
                }

                span:nth-child(3) {
                    display: inline-block;
                    width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .invite {
            margin-bottom: 6px;
        }

        .invite-people {
            position: relative;
            display: flex;

            img {
                border-radius: 50%;
            }

            .change-btn {
                height: 18px;
                line-height: 17px;
                position: absolute;
                top: 6px;
                right: 6px;
                color: #FF98A0;
                padding: 0px 6px;
                border: 1px solid #FF98A0;
                border-radius: 20px;
                box-sizing: border-box;
            }

            .hide-text {
                border-bottom: 1px solid #f1f1f1;
                padding: 6px 0;

                input {
                    width: 80px;
                    border: none;
                }

                span {
                    border-radius: 10px;
                    padding: 2px 6px;
                    background-color: #F1F1F1;
                }
            }
        }
    }
}

.mi-shop-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .mi-shop-content {
        max-width: 70%;
        text-align: center;
        position: relative;
        border-radius: 8px;
        background-color: #fff;

        .user-head {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-top: -25px;
            border: 2px solid #fff;
        }

        .van-icon-close {
            position: absolute;
            top: 7px;
            right: 7px;
            color: #9c9c9c;
            font-size: 25px;
            //background-color: #e5e5e5;
        }

        .agent-name {
            font-size: 16px;
            padding-top: 5px;
            padding-bottom: 10px;
        }

        .shop-level,
        .agent-phone {
            color: #666;
        }

        .wx-app-code {
            padding: 22px 0 11px;
            position: relative;

            img {
                width: 60%;
            }

            &:before {
                content: '';
                position: absolute;
                top: 11px;
                left: 0;
                right: 0;
                height: 1px;
                background-image: linear-gradient(to right, #999 0%, #999 50%, transparent 50%);
                background-size: 8px 1px;
                background-repeat: repeat-x;
            }
        }

        .prompt {
            color: #666;
            font-size: 14px;
            padding: 11px 0;
            border-top: 1px solid #e0e0e0;
        }
    }
}
</style>